import { AxiosResponse } from "axios";
import useApi from "../services/ApiService";
import { Paging } from "../models/Paging";
import { MigrationFlagStatus } from "../models/Flags/MigrationFlagStatus";
import {FirmDocumentsFolder, FirmDocumentsFolderWithStatistics} from "../models/FirmDocumentFolder/FirmDocumentsFolder";
import {FirmDocument} from "../models/FirmDocumentFolder/FirmDocument";
import { saveAs } from "file-saver";

export interface GetFirmDocumentsFoldersQueryOptions {
  organizationId?: string;
  status?: string[] | null;
  isLocked?: boolean | null;
  createdBy?: string[];
  folderPath?: string[] | null;
  ids?: string[];
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}
export class GetFirmDocumentsForFirmDocumentsFolderQueryOptions {
  status?: string[] | null;
  extension?: string[] | null;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
  filePath?: string | null;
  minFileSize?: number | null;
  maxFileSize?: number | null;
  type?: number | null;
}

export class FirmDocumentsFoldersRepository {
  private api = useApi();

  async getFirmDocumentsFolders(
    options: GetFirmDocumentsFoldersQueryOptions = {}
  ): Promise<{ data: FirmDocumentsFolder[]; paging: Paging | null }> {
    const {
      status = null,
      isLocked = null,
      createdBy = null,
      folderPath = null,
      ids = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
    } = options;

    try {
      const response = await this.api.get("/api/firmDocumentsFolders", {
        params: {
          Status: status,
          IsLocked: isLocked,
          CreatedBy: createdBy,
          FolderPath: folderPath,
          Id: ids,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }

      return { data, paging };
    } catch (error) {
      throw new Error("Failed to fetch firm documents folders");
    }
  }

  async getFirmDocumentsFolder(
    id: string
  ): Promise<FirmDocumentsFolderWithStatistics> {
    try {
      const response = await this.api.get(`/api/firmDocumentsFolders/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to fetch firm documents folder with id: ${id}`);
    }
  }

  async getFirmDocumentsForFirmDocumentsFolder(
    id: string,
    options: GetFirmDocumentsForFirmDocumentsFolderQueryOptions = {}
  ): Promise<{ data: FirmDocument[]; paging: Paging | null }> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
      filePath = null,
      minFileSize = null,
      maxFileSize = null,
      type = null,
    } = options;
    try {
      const response = await this.api.get(`/api/FirmDocumentsFolders/${id}/files`, {
        params: {
          Status: status,
          Extension: extension,
          FilePath: filePath,
          MinFileSize: minFileSize,
          MaxFileSize: maxFileSize,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
          Type: type,
        },
        paramsSerializer: {
          indexes: true,
        },
        timeout: 100 * 1000,
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }
      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch firm documents for firm documents folder with id: ${id}`);
    }
  }

  async updateFirmDocumentsFolderFlag(
    firmDocumentsFolderId: string,
    flagStatus: MigrationFlagStatus
  ): Promise<void> {
    try {
      const data: Record<string, any> = {
        firmDocumentsFolderId: firmDocumentsFolderId,
        flagStatus: flagStatus,
      };

      const response: AxiosResponse<void> = await this.api.patch(
        `/api/firmDocumentsFolders/updateFlag`,
        data
      );

      return response.data;
    } catch (error) {
      throw new Error(`Failed to update flag info of firm documents folder with id: ${firmDocumentsFolderId}`);
    }
  }

  async updateFirmDocumentsFolderBlock(
    firmDocumentsFolderId: string, 
    block: boolean
  ): Promise<void> {
    try {
      const data: Record<string, any> = {
        entityId: firmDocumentsFolderId,
        block: block,
      };

      const response: AxiosResponse<void> = await this.api.patch(
        `/api/firmDocumentsFolders/updateBlock`,
        data
      );

      return response.data;
    } catch (error) {
      throw new Error(`Failed to update block info of firm documents folder with id: ${firmDocumentsFolderId}`);
    }
  }

  async downloadSharepointFile(
    firmDocumentsFolderId: string,
    firmDocumentsId: string
  ): Promise<void> {
    try {
      const data: Record<string, any> = {
        entityId: firmDocumentsFolderId,
        fileId: firmDocumentsId,
      };

      const response: AxiosResponse<any> = await this.api.post(
        `/api/firmDocumentsFolders/sharepoint/download`,
        data
      );
      saveAs(new Blob([response.data.file]), response.data.name);
    } catch (error) {
      throw new Error(`Failed to download file for firmDocumentsFolderId: ${firmDocumentsFolderId} and firmDocumentsId: ${firmDocumentsId}`);
    }
  }
}

export default FirmDocumentsFoldersRepository;
