export enum ImplementationType {
  AzureBlob = "azureBlob",
  Filevine = "filevine",
  Dropbox = "dropbox",
  Needles4 = "needles4",
  Needles5SQL = "needles5SQL",
  Needles5Sybase = "needles5Sybase",
  TrialWorks = "trialWorks",
  DocsToNeos = "docsToNeos",
}

export function getImplementationTypeName(
  implementationType?: ImplementationType | null
): string {
  switch (implementationType) {
    case ImplementationType.AzureBlob:
      return "Azure Blob";
    case ImplementationType.Filevine:
      return "Filevine";
    case ImplementationType.Dropbox:
      return "Dropbox";
    case ImplementationType.Needles4:
      return "Needles 4";
    case ImplementationType.Needles5SQL:
      return "Needles 5 - SQL";
    case ImplementationType.Needles5Sybase:
      return "Needles 5 - Sybase";
    case ImplementationType.TrialWorks:
      return "TrialWorks";
    case ImplementationType.DocsToNeos:
      return "Docs To Neos";
    default:
      return "Unknown";
  }
}
