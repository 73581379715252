import Delete from "@mui/icons-material/Delete";
import { Box, Grid, IconButton, Input, ListItem } from "@mui/joy";
import * as React from "react";
import AddableList from "../common/AddableList";
import GenericSelect, {SelectOption} from "../common/GenericSelect";
import {MigrationType} from "../../models/Statistics/MigrationType";
import {DropboxRootFolder} from "../../models/ClientConfiguration/DropboxRootFolder";

const migrationTypeSelectOptions: SelectOption<MigrationType>[] = [
  { id: MigrationType.CasesMigration, label: "Cases" },
  { id: MigrationType.IntakesMigration, label: "Intakes" },
  { id: MigrationType.ProvidersMigration, label: "Providers" },
  { id: MigrationType.FirmDocumentsMigration, label: "Firm Documents" },
  { id: MigrationType.TemplatesMigration, label: "Template Files" },
];

interface DropboxRootFoldersConfigurationControlProps {
  dropboxRootFolders: DropboxRootFolder[];
  onChange: (folders: DropboxRootFolder[]) => void;
}

const DropboxRootFoldersConfigurationControl: React.FC<
  DropboxRootFoldersConfigurationControlProps
> = ({
  dropboxRootFolders,
  onChange
}) => {
  
  const handleAddClick = () => {
    const newRootFolder: DropboxRootFolder = {
      folderPath: "",
      migrationType: MigrationType.CasesMigration,
    };
    const updatedRootFolders = [...dropboxRootFolders, newRootFolder];
    onChange(updatedRootFolders);
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleChangeRootFolder = (index: number, value: string) => {
    let formattedValue = value.trim();
    formattedValue = formattedValue.replace(/\\/g, "/");
    if (!formattedValue.startsWith("/"))
      formattedValue = "/" + formattedValue;

    const updatedRootFolders = [...dropboxRootFolders];
    updatedRootFolders[index].folderPath = formattedValue;
    onChange(updatedRootFolders);
  };


  const handleChangeRootFolderMigrationType = (index: number, value: MigrationType) => {
    const updatedRootFolders = [...dropboxRootFolders];
    updatedRootFolders[index].migrationType = value;
    onChange(updatedRootFolders);
  };
  
  const handleDeleteRootFolder = (index: number) => {
    const updatedRootFolders = [...dropboxRootFolders];
    updatedRootFolders.splice(index, 1);
    onChange(updatedRootFolders);
  };

  return (
    <Box>
      <AddableList
        sx={{ overflow: "auto", maxHeight: "25vh" }}
        onAddClick={handleAddClick}
        subheaderText="Root Folders"
      >
        {dropboxRootFolders.map((rootFolder, index) => {
          const migrationTypeSelectOption = migrationTypeSelectOptions.find(
            (option) => option.id === rootFolder.migrationType) 
            || migrationTypeSelectOptions[0];

          return (
            <ListItem
              endAction={
                <IconButton
                  aria-label="Delete"
                  size="sm"
                  color="danger"
                  onClick={() => handleDeleteRootFolder(index)}
                >
                  <Delete />
                </IconButton>
              }
              key={index}
            >
              <Grid container spacing={1} width={"100%"} sx={{ paddingRight: "20px" }}>
                <Grid xs={9}>
                  <Input
                    fullWidth
                    size="sm"
                    value={rootFolder.folderPath ?? ""}
                    // onBlur={removeDuplicates}
                    onChange={(event) => handleChangeRootFolder(index, event.target.value)}
                    onKeyDown={(event) => handleKeyPress(event as React.KeyboardEvent<HTMLInputElement>, index)}
                  />
                </Grid>
                <Grid xs={3}>
                  <GenericSelect
                    size="sm"
                    options={migrationTypeSelectOptions}
                    placeholder="Select migration type"
                    inputValue={migrationTypeSelectOption}
                    setValue={(value) => handleChangeRootFolderMigrationType(index, value.id)
                    }
                  />
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </AddableList>
    </Box>
  );
};

export default DropboxRootFoldersConfigurationControl;
