import React from "react";
import {
  JobEntityType,
  JobEntityTypeColors
} from "../../models/Jobs/JobEntityType";

import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  Stack
} from "@mui/joy";

import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

interface ConfirmFirmDocumentsDeletionModalProps {
  open: boolean;
  folderPath: string;
  filePaths: string[];
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmFirmDocumentsDeletionModal: React.FC<
  ConfirmFirmDocumentsDeletionModalProps
> = ({ 
  open,
  folderPath, 
  filePaths,
  onClose, 
  onConfirm 
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" role="alertdialog" maxWidth={"35%"}>
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmation required
        </DialogTitle>
        <Divider />
        <DialogContent>
          Are you sure you want to delete firm documents from firm documents folder {folderPath}?
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            sx={{ flexWrap: "wrap", mt: 2 }}
          >
            {filePaths.map((path) => (
              <Chip
                key={path}
                variant="soft"
                size={"sm"}
                color={JobEntityTypeColors[JobEntityType.FirmDocumentsFolder]}
              >
                {path}
              </Chip>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="solid" onClick={() => onConfirm()} color="primary">
            Confirm
          </Button>
          <Button variant="plain" color="neutral" onClick={() => onClose()}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmFirmDocumentsDeletionModal;
