import React from "react";
import LabelValuePair from "../common/LabelValuePair";
import {
  AuditLog,
  AuditLogType,
  AuditLogTypeLabels,
} from "../../models/AuditLog";

import {
  Modal,
  ModalDialog,
  DialogTitle,
  Divider,
  DialogContent,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/joy";

import CloseIcon from "@mui/icons-material/Close";

const ViewExportEntitiesAuditLogDetailsModal: React.FC<{
  auditLog: AuditLog | null;
  open: boolean;
  onClose: () => void;
  organizationId: string;
}> = ({ auditLog, open, onClose, organizationId }) => {
  const generateDynamicLink = (entityId: string | null, type: AuditLogType) => {
    if (entityId === null) return `/organization/${organizationId}/audit-logs`;

    switch (type) {
      case AuditLogType.CasesExport:
        return `/organization/${organizationId}/case/${entityId}`;
      case AuditLogType.FirmDocumentsFoldersExport:
        return `/organization/${organizationId}/firm-documents-folders/${entityId}`;
      case AuditLogType.ProvidersExport:
        return `/organization/${organizationId}/provider/${entityId}`;
      default:
        return `/organization/${organizationId}/audit-logs`;
    }
  };

  const getLabel = (eventType: AuditLogType): string => {
    switch (eventType) {
      case AuditLogType.CasesExport:
        return "Case numbers:";
      case AuditLogType.FirmDocumentsFoldersExport:
        return "Folder paths:";
      default:
        return "";
    }
  };

  const parseAdditionalData = (
    additionalData: string,
    eventType: AuditLogType
  ) => {
    try {
      const additionalDataArray = JSON.parse(additionalData);
      if (Array.isArray(additionalDataArray)) {
        switch (eventType) {
          case AuditLogType.CasesExport:
            return additionalDataArray.map(
              (caseData: { Id: string; CaseNumber: string }, index: number) => (
                <Typography key={index} level="body-sm">
                  <Link
                    href={generateDynamicLink(caseData.Id, eventType)}
                    underline="hover"
                  >
                    {caseData.CaseNumber}
                  </Link>
                </Typography>
              )
            );
          case AuditLogType.FirmDocumentsFoldersExport:
            return additionalDataArray.map(
              (
                firmDocumentsFolderData: { Id: string; FolderPath: string },
                index: number
              ) => (
                <Typography key={index} level="body-sm">
                  <Link
                    href={generateDynamicLink(
                      firmDocumentsFolderData.Id,
                      eventType
                    )}
                    underline="hover"
                  >
                    {firmDocumentsFolderData.FolderPath}
                  </Link>
                </Typography>
              )
            );
          case AuditLogType.ProvidersExport:
            return additionalDataArray.map(
              (
                providerData: { Id: string; ProviderNameId: string },
                index: number
              ) => (
                <Typography key={index} level="body-sm">
                  <Link
                    href={generateDynamicLink(providerData.Id, eventType)}
                    underline="hover"
                  >
                    {providerData.ProviderNameId}
                  </Link>
                </Typography>
              )
            );
          default:
            return <Typography level="body-sm">Invalid data format</Typography>;
        }
      }
      return <Typography level="body-sm">Invalid data format</Typography>;
    } catch (error) {
      return (
        <Typography level="body-sm">Failed to parse additionalData</Typography>
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        width: {
          xs: "100vw",
          lg: "calc(100vw + 15vw)",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {auditLog ? (
        <ModalDialog
          variant="outlined"
          sx={{ width: "65%", maxHeight: "90vh", overflow: "hidden" }}
        >
          <DialogTitle>
            {AuditLogTypeLabels[auditLog.eventType]}
            <IconButton
              variant="plain"
              color="neutral"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 12,
                top: 12,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent sx={{ m: 0 }}>
            <Stack direction="column" gap={1}>
              <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                {getLabel(auditLog.eventType)}
              </Typography>
              {auditLog.additionalData ? (
                parseAdditionalData(auditLog.additionalData, auditLog.eventType)
              ) : (
                <></>
              )}
            </Stack>
          </DialogContent>

          <Divider />

          <LabelValuePair
            label="Peroformed By"
            value={auditLog.performerEmailAddress}
          />

          <LabelValuePair label="Event Date" value={auditLog.eventDate} />
        </ModalDialog>
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default ViewExportEntitiesAuditLogDetailsModal;
