import React from "react";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import MigrationStatusChip from "../components/common/MigrationStatusChip";
import FirmDocumentsTable, {FirmDocumentsTableRef} from "../components/FirmDocumentsFolder/FirmDocumentsTable";
import DeleteFirmDocumentsStatusTable from "../components/FirmDocumentsFolder/DeleteFirmDocumentsStatusTable";
import CreateDeleteFirmDocumentsRequestTable from "../components/FirmDocumentsFolder/CreateDeleteFirmDocumentsRequestTable";
import FirmDocumentsFoldersRepository from "../repositories/FirmDocumentsFoldersRepository";
import {FirmDocumentsFolderWithStatistics} from "../models/FirmDocumentFolder/FirmDocumentsFolder";
import {FirmDocument} from "../models/FirmDocumentFolder/FirmDocument";
import {
  MigrationStatus,
  MigrationStatusColors,
} from "../models/MigrationStatus";

import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Tab,
  tabClasses,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import ExportFirmDocumentsToCsvStatusTable from "../components/FirmDocumentsFolder/ExportFirmDocumentsToCsvStatusTable";
import ConfirmFirmDocumentsExportToCsvModal
  from "../components/FirmDocumentsFolder/ConfirmFirmDocumentsExportToCsvModal";

export enum ActiveFirmDocumentsJobsExplorer {
  DeleteFirmDocuments = 0,
  ExportCsv = 1,
}

const FirmDocumentsFolderPage = () => {
  const firmDocumentFoldersRepository = new FirmDocumentsFoldersRepository();
  const { organizationId, firmDocumentsFolderId } = useParams();
  const [firmDocumentFolderEntity, setFirmDocumentsFolder] = useState<FirmDocumentsFolderWithStatistics | undefined>(undefined);
  const [newFirmDocumentsForDeletion, setNewFirmDocumentsForDeletion] = useState<FirmDocument[]>([]);
  const [selectedTab, setSelectedTab] = useState<ActiveFirmDocumentsJobsExplorer>(ActiveFirmDocumentsJobsExplorer.DeleteFirmDocuments);
  const [isConfirmExportModalOpen, setIsConfirmExportModalOpen] = React.useState<boolean>(false);
  const firmDocumentsTableRef = useRef<FirmDocumentsTableRef>(null);
  
  const firmDocumentFolderQuery = useQuery(
    ["firmDocumentFolder", firmDocumentsFolderId],
    async () => {
      return await firmDocumentFoldersRepository.getFirmDocumentsFolder(firmDocumentsFolderId ?? "");
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    }
  );
  
  useEffect(() => {
    if (firmDocumentFolderQuery.data)
      setFirmDocumentsFolder(firmDocumentFolderQuery.data);
    
  }, [firmDocumentFolderQuery.data, firmDocumentsFolderId]);

  const { isLoading, isError } = firmDocumentFolderQuery;
  
  if (isLoading || isError || !firmDocumentFolderEntity) {
    return <></>;
  }

  const startCurrentViewExportToCsv = () => {
    firmDocumentsTableRef.current?.exportCurrentViewToCsv();
  };

  const migratedPercentage = firmDocumentFolderEntity.statistics.totalNumberOfFiles > 0 && firmDocumentFolderEntity.statistics.filesSizeInMb > 0
    ? (firmDocumentFolderEntity.statistics.filesMigrated / firmDocumentFolderEntity.statistics.totalNumberOfFiles) * 100
    : 0;

  return (
    <Stack maxHeight={"92vh"}>
      <Box
        sx={{
          width: "100%",
          my: 1,
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <Stack>
          <Typography level="h2">
            Firm Documents Folder
            <MigrationStatusChip
              status={firmDocumentFolderEntity.status}
              size="md"
              style={{ marginLeft: "14px" }} 
            />
          </Typography>
          <Typography level="body-lg" sx={{mb: 0.5}}>
            {firmDocumentFolderEntity.folderPath}
          </Typography>
          <Stack
            width="100%"
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Stack
              flex={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Started</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotStarted]}
                >
                  {
                    firmDocumentFolderEntity.statistics.totalNumberOfFiles 
                    - (firmDocumentFolderEntity.statistics.filesMigrated 
                    + firmDocumentFolderEntity.statistics.filesInterrupted
                    + firmDocumentFolderEntity.statistics.filesNotFound)
                  }
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Migrated</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Finished]}
                >
                  {firmDocumentFolderEntity.statistics.filesMigrated}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Interrupted</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Interrupted]}
                >
                  {firmDocumentFolderEntity.statistics.filesInterrupted}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Found</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotFound]}
                >
                  {firmDocumentFolderEntity.statistics.filesNotFound}
                </Chip>
              </FormControl>

              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Files Count</FormLabel>
                <Chip variant="soft" size="sm" color="neutral">
                  {firmDocumentFolderEntity.statistics.totalNumberOfFiles}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Size (MB)</FormLabel>
                <Chip variant="soft" size="sm" color="primary">
                  {firmDocumentFolderEntity.statistics.filesSizeInMb.toFixed(2)}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Progress</FormLabel>
                <Chip
                  sx={{ textAlign: "center" }}
                  variant="soft"
                  size="sm"
                  color={migratedPercentage > 99.99 ? "success" : "primary"}
                >
                  {migratedPercentage % 1 > 0.99 ||
                  migratedPercentage % 1 < 0.01
                    ? `${migratedPercentage.toFixed(0)}%`
                    : `${migratedPercentage.toFixed(2)}%`}
                </Chip>
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      
      <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
        
        {/*Firm Documents Table*/}
        <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
          <Card>
            <FirmDocumentsTable
              ref={firmDocumentsTableRef}
              organizationId={organizationId ?? ""}
              firmDocumentsFolderId={firmDocumentsFolderId ?? ""}
              activeJobsExplorer={selectedTab}
              newFirmDocumentsForDeletion={newFirmDocumentsForDeletion}
              setNewFirmDocumentsForDeletion={setNewFirmDocumentsForDeletion}
            />
          </Card>
        </Grid>
        
        <Grid
          xs={12}
          lg={3}
          display={"flex"}
          maxHeight={"100%"}
          height={"100%"}
        >
          <Card sx={{ width: "100%", padding: 0}}>
            <Tabs
              value={selectedTab}
              sx={{
                maxHeight: "100%",
                borderRadius: "sm",
                overflow: "auto",
                height: "100%",
              }}
              onChange={(event, newValue) =>
                setSelectedTab(newValue as ActiveFirmDocumentsJobsExplorer)
              }
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "md",
                    [`&[aria-selected="true"]`]: {
                      bgcolor: "background.surface",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Delete files
                </Tab>
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Export csv
                </Tab>
              </TabList>
              <TabPanel
                value={ActiveFirmDocumentsJobsExplorer.DeleteFirmDocuments}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  {newFirmDocumentsForDeletion.length > 0 && (
                    <>
                      <Divider sx={{ paddingTop: 2 }}>
                        <b>New</b>
                      </Divider>
                      <CreateDeleteFirmDocumentsRequestTable
                        organizationId={organizationId ?? ""}
                        firmDocumentsFolderId={firmDocumentsFolderId ?? ""}
                        folderPath={firmDocumentFolderEntity.folderPath}
                        newFilesForDeletion={newFirmDocumentsForDeletion}
                        setNewFirmDocumentsForDeletion={setNewFirmDocumentsForDeletion}
                      />
                      <Divider>
                        <b>Deletion status</b>
                      </Divider>
                    </>
                  )}
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <DeleteFirmDocumentsStatusTable
                      organizationId={organizationId ?? ""}
                      firmDocumentsFolderId={firmDocumentsFolderId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
              <TabPanel
                value={ActiveFirmDocumentsJobsExplorer.ExportCsv}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  <Card
                    sx={{
                      padding: "1em 0 0 0",
                      border: 0,
                      width: "100%",
                      height: "50%",
                    }}
                  >
                    <Button onClick={() => setIsConfirmExportModalOpen(true)}>
                      Export current files view
                    </Button>
                    <ExportFirmDocumentsToCsvStatusTable
                      organizationId={organizationId ?? ""}
                      firmDocumentsFolderId={firmDocumentsFolderId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
      </Grid>

      {/* Confirm CSV export modal */}
      {isConfirmExportModalOpen ? (
        <ConfirmFirmDocumentsExportToCsvModal
          open={isConfirmExportModalOpen}
          onClose={() => {
            setIsConfirmExportModalOpen(false);
          }}
          onConfirm={async () => {
            startCurrentViewExportToCsv();
            setIsConfirmExportModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default FirmDocumentsFolderPage;
