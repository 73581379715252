import React from "react";
import { Typography, Tooltip } from "@mui/joy";

interface ShortenedPathProps {
  folderPath: string;
  maxLength?: number; 
}

const ShortenedPath: React.FC<ShortenedPathProps> = ({ folderPath, maxLength = 30 }) => {
  const shortenPath = (path: string, maxLen: number): string => {
    if (path.length <= maxLen) {
      return path;
    }
    const start = path.slice(0, maxLen);
    return `${start}...`;
  };

  const shortenedPath = shortenPath(folderPath, maxLength);

  return (
    <Tooltip title={folderPath} placement="bottom-start" >
      <Typography level="body-sm" noWrap>
        {shortenedPath}
      </Typography>
    </Tooltip>
  );
};

export default ShortenedPath;
