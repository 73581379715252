import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import { convertUtcToLocal } from "../../helpers";
import ViewConfigurationChangeAuditLogDetailsModal from "./ViewConfigurationChangeAuditLogDetailsModal";
import ViewExportEntitiesAuditLogDetailsModal from "./ViewExportEntitiesAuditLogDetailsModal";
import {
  AuditLogQueryParams,
  AuditLogsRepository,
} from "../../repositories/AuditLogsRepository";
import { Order } from "../../helpers/TableHelpers";
import {
  AuditLog,
  AuditLogType,
  AuditLogTypeLabels,
} from "../../models/AuditLog";

import {
  Skeleton,
  Box,
  Sheet,
  Table,
  Typography,
  FormControl,
  FormLabel,
  IconButton,
  Select,
  Option,
  Chip,
} from "@mui/joy";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ViewExportFilesAuditLogDetailsModal from "./ViewExportFilesAuditLogDetailsModal";
import ViewDownloadFileAuditLogDetailsModal from "./ViewDownloadFileAuditLogDetailsModal";

interface AuditLogTableProps {
  queryKey: string;
  organizationId: string;
  queryParams: AuditLogQueryParams;
  shouldFetchAuditLogs: boolean;
  setShouldFetchAuditLogs: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuditLogTable: React.FC<AuditLogTableProps> = ({
  queryKey,
  organizationId,
  queryParams,
  shouldFetchAuditLogs,
  setShouldFetchAuditLogs,
}) => {
  const auditLogsRepository = new AuditLogsRepository();
  const [order, setOrder] = useState<Order>("desc");
  const [sort, setSort] = useState<string>("dateCreated");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [rows, setRows] = useState(0);
  const [viewAuditLogDetailsOpened, setViewAuditLogDetailsOpened] =
    useState<boolean>(false);
  const [selectedAuditLog, setSelectedAuditLog] = useState<AuditLog | null>(
    null
  );

  const auditLogsQuery = useQuery(
    [queryKey, organizationId, page, rowsPerPage, sort, order, queryParams],
    async () => {
      return await auditLogsRepository.getAuditLogs({
        params: queryParams,
        descending: order === "desc",
        orderBy: sort,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
      });
    },
    {
      enabled: shouldFetchAuditLogs,
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );

  const { isError, isFetching } = auditLogsQuery;

  useEffect(() => {
    if (auditLogsQuery.data?.data) {
      setAuditLogs(auditLogsQuery.data.data);
      setRows(auditLogsQuery.data.paging?.totalCount ?? 0);
      setShouldFetchAuditLogs(false);
    }
  }, [auditLogsQuery]);

  const changeSort = (newSort: string) => {
    setShouldFetchAuditLogs(true);
    setPage(0);
    setSort(newSort);
  };

  const changeOrder = (newOrder: Order) => {
    setShouldFetchAuditLogs(true);
    setPage(0);
    setOrder(newOrder);
  };

  const handleChangePage = (newPage: number) => {
    setShouldFetchAuditLogs(true);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setShouldFetchAuditLogs(true);
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows === -1) return (page + 1) * rowsPerPage;

    return rowsPerPage === -1 ? rows : Math.min(rows, (page + 1) * rowsPerPage);
  };

  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const handleOpenModal = (auditLog: AuditLog) => {
    setViewAuditLogDetailsOpened(true);
    setSelectedAuditLog(auditLog);
  };

  const renderAuditLogDetailsModal = () => {
    if (!selectedAuditLog) return null;

    switch (selectedAuditLog.eventType) {
      case AuditLogType.OrganizationConfigurationChange:
      case AuditLogType.ClientConfigurationChange:
        return (
          <ViewConfigurationChangeAuditLogDetailsModal
            auditLog={selectedAuditLog}
            open={viewAuditLogDetailsOpened}
            onClose={() => setViewAuditLogDetailsOpened(false)}
            organizationId={organizationId}
          />
        );
      case AuditLogType.CasesExport:
      case AuditLogType.FirmDocumentsFoldersExport:
      case AuditLogType.ProvidersExport:
        return (
          <ViewExportEntitiesAuditLogDetailsModal
            auditLog={selectedAuditLog}
            open={viewAuditLogDetailsOpened}
            onClose={() => setViewAuditLogDetailsOpened(false)}
            organizationId={organizationId}
          />
        );
      case AuditLogType.CaseFilesExport:
      case AuditLogType.FirmDocumentsExport:
      case AuditLogType.ProviderFilesExport:
        return (
          <ViewExportFilesAuditLogDetailsModal
            auditLog={selectedAuditLog}
            open={viewAuditLogDetailsOpened}
            onClose={() => setViewAuditLogDetailsOpened(false)}
            organizationId={organizationId}
          />
        );
      case AuditLogType.CaseFileDownload:
      case AuditLogType.IntakeFileDownload:
      case AuditLogType.FirmDocumentDownload:
      case AuditLogType.ProviderFileDownload:
        return (
          <ViewDownloadFileAuditLogDetailsModal
            auditLog={selectedAuditLog}
            open={viewAuditLogDetailsOpened}
            onClose={() => setViewAuditLogDetailsOpened(false)}
            organizationId={organizationId}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Sheet
        className="AuditLogTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            stickyHeader
            stickyFooter
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "1.5%" }}></th>
                <th style={{ width: "35%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Event Type"}
                    sortProperty={"eventType"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "25%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Event Date"}
                    sortProperty={"dateCreated"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "25%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Performed By"}
                    sortProperty={"performedBy"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "15%", padding: "12px 6px" }}></th>
              </tr>
            </thead>

            <tbody>
              {auditLogs.map((auditLog) => (
                <tr key={auditLog.id}>
                  <td></td>
                  <td>
                    <Chip variant="soft" size="sm" color="neutral">
                      {AuditLogTypeLabels[auditLog.eventType]}
                    </Chip>
                  </td>
                  <td>{convertUtcToLocal(auditLog.eventDate)}</td>
                  <td>{auditLog.performerEmailAddress}</td>
                  <td>
                    <span
                      style={{ cursor: "pointer", color: "#007bff" }}
                      onClick={() => handleOpenModal(auditLog)}
                    >
                      View Details
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select
                        onChange={handleChangeRowsPerPage}
                        value={rowsPerPage}
                      >
                        <Option value={10}>10</Option>
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                      </Select>
                    </FormControl>

                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from: rows === 0 ? 0 : page * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows === -1 ? -1 : rows,
                      })}
                    </Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          rows !== -1
                            ? page >= Math.ceil(rows / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Skeleton>
      </Sheet>

      {renderAuditLogDetailsModal()}
    </>
  );
};

export default AuditLogTable;
