/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import Input from "@mui/joy/Input";
import * as React from "react";
// icons
import { Delete, Search } from "@mui/icons-material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, Divider, IconButton, Select, Skeleton } from "@mui/joy";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Order } from "../../helpers/TableHelpers";
import { CaseFile } from "../../models/CaseFile";
import {
  MigrationStatus,
  MigrationStatusColors,
  MigrationStatusLabels,
} from "../../models/MigrationStatus";
import CasesRepository from "../../repositories/CasesRepository";
import { ClearableSelect } from "../common/ClearableSelect";
import FileExtensionsInput from "../common/FileExtensionsInput";
import MigrationStatusChip from "../common/MigrationStatusChip";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import { FileType, FileTypeMap } from "../../models/FileType";
import { CaseFileFilters } from "../../models/CaseFileFilters";
import { ActiveCaseFilesJobsExplorer } from "../../pages/CasePage";
import JobsRepository from "../../repositories/JobsRepository";

import {
  Search as SearchIcon,
  Download as DownloadIcon
} from "@mui/icons-material";

interface CaseFilesTableProps {
  organizationId: string;
  caseId: string;
  activeJobsExplorer: ActiveCaseFilesJobsExplorer;
  newCaseFilesForDeletion: CaseFile[];
  setNewCasesFilesForDeletion: React.Dispatch<React.SetStateAction<CaseFile[]>>;
}
export interface CaseFilesTableRef {
  exportCurrentViewToCsv: () => void;
}
const CaseFilesTable = forwardRef<CaseFilesTableRef, CaseFilesTableProps>(
  (
    {
      organizationId,
      caseId,
      activeJobsExplorer,
      newCaseFilesForDeletion,
      setNewCasesFilesForDeletion,
    },
    ref
  ) => {
    const [order, setOrder] = React.useState<Order>("asc");
    const [sort, setSort] = React.useState<string>("status");
    const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
    const [extensionFilter, setExtensionFilter] = React.useState<string | null>(
      null
    );
    const [filePathFilter, setFilePathFilter] = useState<string | null>("");
    const [minSizeFilter, setMinSizeFilter] = useState<number | null>(null);
    const [maxSizeFilter, setMaxSizeFilter] = useState<number | null>(null);
    const [nestedFolder1Filter, setNestedFolder1Filter] = React.useState<
      string | null
    >(null);
    const [nestedFolder2Filter, setNestedFolder2Filter] = React.useState<
      string | null
    >(null);
    const [fileTypeFilter, setFileTypeFilter] = React.useState<string | null>(
      FileType.All
    );
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [caseFiles, setCaseFiles] = useState<CaseFile[]>([]);
    const [caseFilesFilters, setCaseFilesFilters] = useState<CaseFileFilters>();
    const [rows, setRows] = useState(0);
    const [extensions, setExtensions] = useState<string[]>([]);
    const [extensionsSearch, setExtensionsSearch] = useState<string[]>([]);
    const casesRepository = new CasesRepository();
    const jobsRepository = new JobsRepository();
    const queryClient = useQueryClient();
    const caseFilesQuery = useQuery(
      [
        "caseFiles",
        caseId,
        page,
        rowsPerPage,
        statusFilter,
        sort,
        order,
        extensionsSearch,
        extensionFilter,
        nestedFolder1Filter,
        nestedFolder2Filter,
        filePathFilter,
        minSizeFilter,
        maxSizeFilter,
        fileTypeFilter,
      ],
      async () => {
        const cases = await casesRepository.getCaseFilesForCase(caseId, {
          descending: order === "desc",
          orderBy: sort,
          status: statusFilter ? [statusFilter] : null,
          extension:
            extensionsSearch || extensionFilter
              ? extensionFilter
                ? [...extensionsSearch, extensionFilter]
                : extensionsSearch
              : null,
          nestedFolder1: nestedFolder1Filter,
          nestedFolder2: nestedFolder2Filter,
          minFileSize: minSizeFilter,
          maxFileSize: maxSizeFilter,
          filePath: filePathFilter,
          type: fileTypeFilter ? FileTypeMap[fileTypeFilter] : null,
          pageNumber: page + 1,
          pageSize: rowsPerPage,
        });
        return cases;
      },
      {
        staleTime: 5 * 60 * 1000,
        refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
        refetchIntervalInBackground: true,
        refetchOnMount: true,
      }
    );

    const caseFilesFiltersQuery = useQuery(
      ["caseFilesFilters", caseId],
      async () => {
        const caseFilters =
          await casesRepository.getCaseFilesFiltersForCase(caseId);
        return caseFilters;
      },
      {
        staleTime: 5 * 60 * 1000,
        refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
        refetchIntervalInBackground: true,
        refetchOnMount: true,
      }
    );

    useEffect(() => {
      if (caseFilesQuery.data?.data) {
        setCaseFiles(caseFilesQuery.data?.data);
        setRows(caseFilesQuery.data.paging?.totalCount ?? 0);
      }
    }, [caseFilesQuery]);

    useEffect(() => {
      const filtersData = caseFilesFiltersQuery.data?.data;
      if (filtersData) {
        setCaseFilesFilters(filtersData);
      }
    }, [caseFilesFiltersQuery]);
    const exportCurrentViewToCsv = async () => {
      const options = {
        descending: order === "desc",
        orderBy: sort,
        status: statusFilter ? [statusFilter] : null,
        extension:
          extensionsSearch || extensionFilter
            ? extensionFilter
              ? [...extensionsSearch, extensionFilter]
              : extensionsSearch
            : null,
        nestedFolder1: nestedFolder1Filter,
        nestedFolder2: nestedFolder2Filter,
        minFileSize: minSizeFilter,
        maxFileSize: maxSizeFilter,
        filePath: filePathFilter,
        type: fileTypeFilter ? FileTypeMap[fileTypeFilter] : null,
      };

      await jobsRepository.startCsvGenerationFromCaseFiles(
        organizationId,
        caseId,
        options
      );
      queryClient.invalidateQueries("jobs");
      queryClient.invalidateQueries([
        "exportCaseFilesToCsv",
        organizationId,
        caseId,
      ]);
    };

    useImperativeHandle(ref, () => ({
      exportCurrentViewToCsv,
    }));

    const handleDownloadFromSharepoint = async (fileId: string) => {
      await casesRepository.downloadSharepointFile(
        caseId,
        fileId
      );
    };
    
    const handleChangePage = (newPage: number) => {
      setPage(newPage);
    };
    const changeSort = (newSort: string) => {
      setPage(0);
      setSort(newSort);
    };
    const changeOrder = (newOrder: Order) => {
      setPage(0);
      setOrder(newOrder);
    };
    const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
      setRowsPerPage(parseInt(newValue!.toString(), 10));
      setPage(0);
    };

    const handleAddCaseFileForDeletion = (caseFileEntity: CaseFile) => {
      if (!disabledRows.has(caseFileEntity.id)) {
        setNewCasesFilesForDeletion((prevRows) => [
          ...prevRows,
          caseFileEntity,
        ]);
      }
    };
    const getDisabledRows = () => {
      switch (activeJobsExplorer) {
        case ActiveCaseFilesJobsExplorer.DeleteCaseFiles:
          return new Set(newCaseFilesForDeletion.map((c) => c.id));
      }
      return new Set<string>();
    };
    const handleFilePathSearch = (filePath: string) => {
      setFilePathFilter(filePath);
    };

    const handleMaxSizeFilter = (size: string) => {
      const maxSize = parseToNumberOrNull(size);
      setMaxSizeFilter(maxSize);
    };

    const handleMinSizeFilter = (size: string) => {
      const minSize = parseToNumberOrNull(size);
      setMinSizeFilter(minSize);
    };

    const parseToNumberOrNull = (value: string) => {
      return value !== "" && value !== null ? Number(value) : null;
    };

    const getLabelDisplayedRowsTo = () => {
      if (rows === -1) {
        return (page + 1) * rowsPerPage;
      }
      return rowsPerPage === -1
        ? rows
        : Math.min(rows, (page + 1) * rowsPerPage);
    };

    function labelDisplayedRows({
      from,
      to,
      count,
    }: {
      from: number;
      to: number;
      count: number;
    }) {
      return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
    }
    const { isError, isFetching } = caseFilesQuery;

    const getActiveButtonForExplorer = (
      caseFileEntity: CaseFile,
      isDisabled: boolean
    ) => {
      switch (activeJobsExplorer) {
        case ActiveCaseFilesJobsExplorer.DeleteCaseFiles:
          return (
            <IconButton
              aria-label="Delete"
              disabled={isDisabled}
              size="sm"
              color="danger"
              onClick={() => handleAddCaseFileForDeletion(caseFileEntity)}
            >
              <Delete />
            </IconButton>
          );

        default:
          <></>;
      }
    };
    const renderFilters = () => (
      <React.Fragment>
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Status</FormLabel>
          <ClearableSelect
            value={statusFilter}
            color={MigrationStatusColors[statusFilter as MigrationStatus]}
            onChange={(newValue) => {
              setPage(0);
              setStatusFilter(newValue);
            }}
            size="sm"
            placeholder="Filter by status"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          >
            {Object.values(MigrationStatus)
              .filter((v) => v !== MigrationStatus.InProgress)
              .map((status) => {
                const migrationStatus = status as MigrationStatus;
                return (
                  <Option
                    value={migrationStatus}
                    color={MigrationStatusColors[migrationStatus]}
                  >
                    {MigrationStatusLabels[migrationStatus]}
                  </Option>
                );
              })}
          </ClearableSelect>
        </FormControl>

        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Extension</FormLabel>
          <ClearableSelect
            value={extensionFilter}
            onChange={(newValue) => {
              setPage(0);
              setExtensionFilter(newValue);
            }}
            size="sm"
            placeholder="Filter by extension"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            color={extensionFilter ? "primary" : "neutral"}
          >
            {caseFilesFilters?.extensions.map((ext) => {
              return <Option value={ext}>{ext}</Option>;
            })}
          </ClearableSelect>
        </FormControl>

        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Document Type</FormLabel>
          <ClearableSelect
            value={nestedFolder1Filter}
            onChange={(newValue) => {
              setPage(0);
              setNestedFolder1Filter(newValue);
            }}
            size="sm"
            placeholder="Filter by document type"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            color={nestedFolder1Filter ? "primary" : "neutral"}
          >
            {caseFilesFilters?.nestedFolders1.map((ext) => {
              return <Option value={ext}>{ext}</Option>;
            })}
          </ClearableSelect>
        </FormControl>

        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Document Category</FormLabel>
          <ClearableSelect
            value={nestedFolder2Filter}
            onChange={(newValue) => {
              setPage(0);
              setNestedFolder2Filter(newValue);
            }}
            size="sm"
            placeholder="Filter by document category"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            color={nestedFolder2Filter ? "primary" : "neutral"}
          >
            {caseFilesFilters?.nestedFolders2.map((ext) => {
              return <Option value={ext}>{ext}</Option>;
            })}
          </ClearableSelect>
        </FormControl>

        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>File Type</FormLabel>
          <ClearableSelect
            value={fileTypeFilter}
            onChange={(newValue) => {
              setPage(0);
              setFileTypeFilter(newValue);
            }}
            size="sm"
            placeholder="Filter by file type"
            color={
              fileTypeFilter !== FileType.All && fileTypeFilter !== null
                ? "primary"
                : "neutral"
            }
          >
            {Object.values(FileType).map((type) => {
              const fileType = type as FileType;
              return <Option value={fileType}>{fileType}</Option>;
            })}
          </ClearableSelect>
        </FormControl>
      </React.Fragment>
    );
    const disabledRows = getDisabledRows();

    return (
      <React.Fragment>
        <Box
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            py: 2,
            display: {
              xs: "none",
              sm: "flex",
            },
            flexWrap: "wrap",
            gap: 1.5,
            "& > *": {
              minWidth: {
                xs: "120px",
                md: "160px",
              },
            },
          }}
        >
          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>Search for files with extension</FormLabel>
            <FileExtensionsInput
              inputLabel={extensions.length > 0 ? "" : "Extensions"}
              extensions={extensions}
              onExtensionsChange={(newExtensions) => {
                setExtensions(newExtensions);
              }}
              usePlaceholderAsLabel={true}
              maxExtensionsPerLine={null}
              endDecorator={
                <React.Fragment>
                  <Divider />
                  <Button
                    onClick={() => {
                      setExtensionsSearch(extensions);
                    }}
                    variant="plain"
                    startDecorator={<Search />}
                  >
                    Search
                  </Button>
                </React.Fragment>
              }
            />
          </FormControl>

          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>Search for files with path</FormLabel>
            <Input
              size="sm"
              placeholder="File path"
              startDecorator={<SearchIcon />}
              onBlur={(event) => handleFilePathSearch(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const target = event.target as HTMLInputElement;
                  target.blur();
                }
              }}
            />
          </FormControl>
          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>Files min size</FormLabel>
            <Input
              size="sm"
              placeholder="Min size"
              startDecorator={<SearchIcon />}
              onBlur={(event) => handleMinSizeFilter(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const target = event.target as HTMLInputElement;
                  target.blur();
                }
              }}
              slotProps={{
                input: {
                  type: "number",
                  min: 0,
                },
              }}
            />
          </FormControl>
          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>Files max size</FormLabel>
            <Input
              size="sm"
              placeholder="Max size"
              startDecorator={<SearchIcon />}
              onBlur={(event) => handleMaxSizeFilter(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const target = event.target as HTMLInputElement;
                  target.blur();
                }
              }}
              slotProps={{
                input: {
                  type: "number",
                  min: 0,
                },
              }}
            />
          </FormControl>
        </Box>
        <Box
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            paddingBottom: "1rem",
            display: {
              xs: "none",
              sm: "flex",
            },
            flexWrap: "wrap",
            gap: 1.5,
            "& > *": {
              minWidth: {
                xs: "120px",
                md: "160px",
              },
            },
          }}
        >
          {renderFilters()}
        </Box>
        <Sheet
          className="CaseFilesTableContainer"
          variant="outlined"
          sx={{
            display: { xs: "initial" },
            width: "100%",
            borderRadius: "sm",
            flexShrink: 1,
            overflow: "auto",
            minHeight: 0,
          }}
        >
          <Skeleton variant="inline" loading={isFetching || isError}>
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              stickyFooter
              hoverRow
              sx={{
                "--TableCell-headBackground":
                  "var(--joy-palette-background-level1)",
                "--Table-headerUnderlineThickness": "1px",
                "--TableRow-hoverBackground":
                  "var(--joy-palette-background-level1)",
                "--TableCell-paddingY": "4px",
                "--TableCell-paddingX": "8px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      width: "5%",
                      minWidth: 48,
                      textAlign: "center",
                      padding: "12px 6px",
                    }}
                  ></th>
                  <th
                    style={{
                      width: "10%",
                      padding: "12px 6px",
                    }}
                  >
                    File ID
                  </th>
                  <th style={{ width: "10%", padding: "12px 6px" }}>
                    <SortableTableColumnHeader
                      label={"Status"}
                      sortProperty={"status"}
                      getSort={() => sort}
                      setSort={(newSort) => changeSort(newSort)}
                      getOrder={() => order}
                      setOrder={(newOrder) => changeOrder(newOrder)}
                    />
                  </th>
                  <th style={{ width: "50%", padding: "12px 6px" }}>
                    File Path / Source Api File ID
                  </th>
                  <th style={{ width: "15%", padding: "12px 6px" }}>
                    <SortableTableColumnHeader
                      label={"Size (MB)"}
                      sortProperty={"fileSize"}
                      getSort={() => sort}
                      setSort={(newSort) => changeSort(newSort)}
                      getOrder={() => order}
                      setOrder={(newOrder) => changeOrder(newOrder)}
                    />
                  </th>

                  <th style={{ width: "10%", padding: "12px 6px" }}>
                    Extension
                  </th>
                  <th
                    style={{
                      width: "5%",
                      minWidth: 48,
                      textAlign: "center",
                      padding: "12px 6px",
                    }}
                  ></th>
                  <th
                    style={{
                      width: "6%",
                      minWidth: 48,
                      textAlign: "center",
                      padding: "12px 6px",
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {caseFiles.map((caseFile) => {
                  const isDisabled = disabledRows.has(caseFile.id);
                  return (
                    <tr key={caseFile.id}>
                      <td style={{ textAlign: "center" }}></td>
                      <td>
                        <Typography level="body-sm">{caseFile.id}</Typography>
                      </td>
                      <td>
                        <MigrationStatusChip status={caseFile.status} />
                      </td>
                      <td>
                        <Typography
                          level="body-sm"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {caseFile.filePath}
                        </Typography>
                      </td>
                      <td>
                        <Chip variant="soft" size="sm" color="neutral">
                          {caseFile.fileSizeInMb.toFixed(2)}
                        </Chip>
                      </td>
                      <td>
                        <Chip variant="soft" size="sm" color="primary">
                          {caseFile.extension ?? "-"}
                        </Chip>
                      </td>
                      <td>
                        {getActiveButtonForExplorer(caseFile, isDisabled)}
                      </td>
                      <td>
                        {caseFile.status === MigrationStatus.Finished ? (
                          <IconButton
                            aria-label="Download"
                            disabled={isDisabled}
                            size="sm"
                            color="primary"
                            onClick={() =>
                              handleDownloadFromSharepoint(caseFile.id)
                            }
                          >
                            <DownloadIcon/>
                          </IconButton>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={8}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        justifyContent: "flex-end",
                      }}
                    >
                      <FormControl orientation="horizontal" size="sm">
                        <FormLabel>Rows per page:</FormLabel>
                        <Select
                          onChange={handleChangeRowsPerPage}
                          value={rowsPerPage}
                        >
                          <Option value={100}>100</Option>
                          <Option value={500}>500</Option>
                          <Option value={1000}>1000</Option>
                        </Select>
                      </FormControl>
                      <Typography textAlign="center" sx={{ minWidth: 80 }}>
                        {labelDisplayedRows({
                          from: rows === 0 ? 0 : page * rowsPerPage + 1,
                          to: getLabelDisplayedRowsTo(),
                          count: rows === -1 ? -1 : rows,
                        })}
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <IconButton
                          size="sm"
                          color="neutral"
                          variant="outlined"
                          disabled={page === 0}
                          onClick={() => handleChangePage(page - 1)}
                          sx={{ bgcolor: "background.surface" }}
                        >
                          <KeyboardArrowLeftIcon />
                        </IconButton>
                        <IconButton
                          size="sm"
                          color="neutral"
                          variant="outlined"
                          disabled={
                            rows !== -1
                              ? page >= Math.ceil(rows / rowsPerPage) - 1
                              : false
                          }
                          onClick={() => handleChangePage(page + 1)}
                          sx={{ bgcolor: "background.surface" }}
                        >
                          <KeyboardArrowRightIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Skeleton>
        </Sheet>
      </React.Fragment>
    );
  }
);

export default CaseFilesTable;
