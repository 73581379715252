/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Order } from "../../helpers/TableHelpers";
import { FlagType } from "../../models/ClientConfiguration/FlaggingConfiguration";
import { JobRequestStatus } from "../../models/Jobs/JobRequestStatus";
import { DeleteFirmDocumentsFolderRequest } from "../../models/Jobs/JobRequest";
import { JobType } from "../../models/Jobs/JobType";
import { JobEntityType } from "../../models/Jobs/JobEntityType";
import { FirmDocumentsFolder } from "../../models/FirmDocumentFolder/FirmDocumentsFolder";
import { ClearableSelect } from "../common/ClearableSelect";
import MigrationStatusChip from "../common/MigrationStatusChip";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import FlagTypeChip from "../common/FlagTypeChip";
import ConfirmFirmDocumentsFolderBlockUpdateModal from "./ConfirmFirmDocumentsFolderBlockUpdateModal";
import ShortenedPath from "../common/ShortenedPath";
import { ActiveFirmDocumentsFolderJobsExplorer } from "../../pages/FirmDocumentsFoldersPage";
import FirmDocumentsFoldersRepository from "../../repositories/FirmDocumentsFoldersRepository";
import {
  MigrationStatus,
  MigrationStatusColors,
  MigrationStatusLabels
} from "../../models/MigrationStatus";

import {
  Box,
  Button,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Skeleton,
  Table,
  Typography
} from "@mui/joy";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import TableViewIcon from "@mui/icons-material/TableView";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import BlockIcon from "@mui/icons-material/Block";

interface FirmDocumentsFoldersTableProps {
  organizationId: string;
  activeJobsExplorer: ActiveFirmDocumentsFolderJobsExplorer;
  newFirmDocumentsFoldersForDeletion: DeleteFirmDocumentsFolderRequest[];
  setNewFirmDocumentsFoldersForDeletion: React.Dispatch<React.SetStateAction<DeleteFirmDocumentsFolderRequest[]>>;
  newFirmDocumentsFoldersForExport: FirmDocumentsFolder[];
  setNewFirmDocumentsFoldersForExport: React.Dispatch<React.SetStateAction<FirmDocumentsFolder[]>>;
}

const FirmDocumentsFoldersTable: React.FC<FirmDocumentsFoldersTableProps> = ({
  organizationId,
  activeJobsExplorer,
  newFirmDocumentsFoldersForDeletion,
  setNewFirmDocumentsFoldersForDeletion,
  newFirmDocumentsFoldersForExport,
  setNewFirmDocumentsFoldersForExport,
}) => {
  const navigate = useNavigate();
  const firmDocumentsFoldersRepository = new FirmDocumentsFoldersRepository();
  
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<string>("status");
  const [lockFilter, setLockFilter] = React.useState<boolean | null>(null);
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [searchForFolderPath, setSearchForFolderPath] = useState<string | null>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [firmDocumentsFolders, setFirmDocumentsFolders] = useState<FirmDocumentsFolder[]>([]);
  const [rows, setRows] = useState(0);
  const [dirtyFirmDocumentsFolderId, setDirtyFirmDocumentsFolderId] = useState<string>("");
  const [dirtyFolderPath, setDirtyFolderPath] = useState<string>("");
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = React.useState<boolean>(false);
  
  const firmDocumentsFoldersQuery = useQuery(
    [
      "firmDocumentsFolders",
      organizationId,
      searchForFolderPath,
      page,
      rowsPerPage,
      statusFilter,
      lockFilter,
      sort,
      order,
    ],
    async () => {
      return await firmDocumentsFoldersRepository.getFirmDocumentsFolders({
        organizationId: organizationId,
        folderPath: searchForFolderPath ? [searchForFolderPath] : null,
        descending: order === "desc",
        orderBy: sort,
        status: statusFilter ? [statusFilter] : null,
        isLocked: lockFilter,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
      });
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (firmDocumentsFoldersQuery.data?.data) {
      setFirmDocumentsFolders(firmDocumentsFoldersQuery.data?.data);
      setRows(firmDocumentsFoldersQuery.data.paging?.totalCount ?? 0);
    }
  }, [firmDocumentsFoldersQuery]);
  
  const handleSearch = (newFolderPath: string) => {
    setSearchForFolderPath(newFolderPath ?? null);
  };
  
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  
  const changeSort = (newSort: string) => {
    setPage(0);
    setSort(newSort);
  };
  
  const changeOrder = (newOrder: Order) => {
    setPage(0);
    setOrder(newOrder);
  };
  
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };
  
  const handleDelete = (
    firmDocumentsFolderId: string,
    folderPath: string
  ) => {
    if (!disabledRows.has(firmDocumentsFolderId)) {
      setNewFirmDocumentsFoldersForDeletion((prevRows) => [
        ...prevRows,
        {
          migrationEntityId: firmDocumentsFolderId,
          folderPath: folderPath,
          status: JobRequestStatus.New,
          type: JobType.Delete,
          entityType: JobEntityType.FirmDocumentsFolder,
          organizationId: organizationId,
        } as DeleteFirmDocumentsFolderRequest,
      ]);
    }
  };
  
  const handleAddFirmDocumentsFolderForExport = (firmDocumentsFolderEntity: FirmDocumentsFolder) => {
    if (!disabledRows.has(firmDocumentsFolderEntity.id))
      setNewFirmDocumentsFoldersForExport((prevRows) => [...prevRows, firmDocumentsFolderEntity]);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows === -1)
      return (page + 1) * rowsPerPage;
    
    return rowsPerPage === -1 ? rows : Math.min(rows, (page + 1) * rowsPerPage);
  };

  const getDisabledRows = () => {
    switch (activeJobsExplorer) {
      case ActiveFirmDocumentsFolderJobsExplorer.DeleteFirmDocumentsFolders:
        return new Set(newFirmDocumentsFoldersForDeletion.map((c) => c.migrationEntityId));
      case ActiveFirmDocumentsFolderJobsExplorer.ExportCsv:
        return new Set(newFirmDocumentsFoldersForExport.map((c) => c.id));
    }
  };

  function labelDisplayedRows({ from, to, count }: { from: number; to: number; count: number; }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const getActiveButtonForExplorer = (
    firmDocumentsFolderEntity: FirmDocumentsFolder,
    isDisabled: boolean
  ) => {
    switch (activeJobsExplorer) {
      case ActiveFirmDocumentsFolderJobsExplorer.DeleteFirmDocumentsFolders:
        return (
          <IconButton
            aria-label="Delete"
            disabled={isDisabled || (!firmDocumentsFolderEntity.hasRootFolderInfo && firmDocumentsFolderEntity.status == MigrationStatus.Finished)}
            size="sm"
            color="danger"
            onClick={() => handleDelete(firmDocumentsFolderEntity.id, firmDocumentsFolderEntity.folderPath)}
          >
            <DeleteIcon />
          </IconButton>
        );
      case ActiveFirmDocumentsFolderJobsExplorer.ExportCsv:
        return (
          <IconButton
            aria-label="Csv"
            disabled={isDisabled}
            size="sm"
            color="success"
            onClick={() => handleAddFirmDocumentsFolderForExport(firmDocumentsFolderEntity)}
          >
            <TableViewIcon />
          </IconButton>
        );
      default:
        <></>;
    }
  };

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <ClearableSelect
          value={statusFilter}
          color={MigrationStatusColors[statusFilter as MigrationStatus]}
          onChange={(newValue) => {
            setPage(0);
            setStatusFilter(newValue);
          }}
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          {Object.values(MigrationStatus)
            .filter((v) => v !== MigrationStatus.NotFound)
            .map((status, idx) => {
              const migrationStatus = status as MigrationStatus;
              return (
                <Option
                  key={idx}
                  value={migrationStatus}
                  color={MigrationStatusColors[migrationStatus]}
                >
                  {MigrationStatusLabels[migrationStatus]}
                </Option>
              );
            })}
        </ClearableSelect>
      </FormControl>
      <FormControl size="sm">
        <FormLabel>Lock</FormLabel>
        <ClearableSelect
          value={null}
          onChange={(newValue) => {
            setPage(0);
            if (newValue === null || newValue === "all") {
              setLockFilter(null);
            } else {
              setLockFilter(newValue === "locked");
            }
          }}
          size="sm"
          placeholder="Filter by lock status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          <Option key={1} value={"locked"}>
            Locked
          </Option>
          <Option key={2} value={"unlocked"}>
            Unlocked
          </Option>
        </ClearableSelect>
      </FormControl>
    </React.Fragment>
  );

  const handleBlockModal = (
    folderPath: string,
    firmDocumentsFolderId: string,
    isBlocked: boolean,
    isOpen: boolean
  ) => {
    setIsBlockModalOpen(isOpen);
    setDirtyFolderPath(folderPath);
    setDirtyFirmDocumentsFolderId(firmDocumentsFolderId);
    setIsBlocked(isBlocked);
  };

  const { isError, isFetching } = firmDocumentsFoldersQuery;
  const disabledRows = getDisabledRows();

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          pb: 0.5,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for folder</FormLabel>
          <Input
            size="sm"
            placeholder="Folder Path"
            startDecorator={<SearchIcon />}
            onBlur={(event) => handleSearch(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const target = event.target as HTMLInputElement;
                target.blur();
              }
            }}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="FirmDocumentsFoldersTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "1.5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>
                <th
                  style={{
                    width: "15%",
                    padding: "12px 6px",
                  }}
                >
                  <SortableTableColumnHeader
                    label={"Path"}
                    sortProperty={"folderPath"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "8%", padding: "12px 6px" }}>Lock</th>

                <th style={{ width: "14%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "10%", padding: "12px 6px" }}>Progress</th>

                <th style={{ width: "12%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Files"}
                    sortProperty={"totalNumberOfFiles"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "12%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Size"}
                    sortProperty={"totalFilesSize"}
                    getSort={() => sort}
                    setSort={(newSort) => changeSort(newSort)}
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "13%", padding: "12px 6px" }}>Flag Type</th>

                <th style={{ width: "18%", padding: "12px 6px" }}>
                  Blocked By
                </th>

                <th style={{ width: "20%", padding: "12px 6px" }}>
                  Flag Status
                </th>

                <th
                  style={{
                    width: "10%",
                    maxWidth: 10,
                    padding: "12px 6px",
                  }}
                ></th>
              </tr>
            </thead>

            <tbody>
              {firmDocumentsFolders.map((firmDocumentFolderEntity) => {
                const migratedPercentage =
                  firmDocumentFolderEntity.statistics.totalNumberOfFiles > 0
                    ? (firmDocumentFolderEntity.statistics.filesMigrated /
                        firmDocumentFolderEntity.statistics.totalNumberOfFiles) *
                      100
                    : -1;
                const isDisabled = disabledRows.has(firmDocumentFolderEntity.id);

                return (
                  <tr
                    key={firmDocumentFolderEntity.id}
                    onDoubleClick={() =>
                      navigate(
                        `/organization/${organizationId}/firm-documents-folders/${firmDocumentFolderEntity.id}`
                      )
                    }
                    style={{ opacity: isDisabled ? 0.2 : 1 }}
                  >
                    <td></td>
                    <td>
                      <Typography level="body-sm">
                        <ShortenedPath
                          folderPath={firmDocumentFolderEntity.folderPath}
                          maxLength={15}
                        />
                      </Typography>
                    </td>
                    <td>
                      {firmDocumentFolderEntity.isLocked ? (
                        <Chip variant="soft" size="sm" color="primary">
                          Locked
                        </Chip>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <MigrationStatusChip status={firmDocumentFolderEntity.status} />
                    </td>
                    <td>
                      {firmDocumentFolderEntity.statistics.totalNumberOfFiles > 0 ? (
                        <Chip
                          sx={{ textAlign: "center" }}
                          variant="soft"
                          size="sm"
                          color={
                            migratedPercentage > 99.99 ? "success" : "primary"
                          }
                        >
                          {migratedPercentage % 1 > 0.99 ||
                          migratedPercentage % 1 < 0.01
                            ? `${migratedPercentage.toFixed(0)}%`
                            : `${migratedPercentage.toFixed(2)}%`}
                        </Chip>
                      ) : (
                        <Chip variant="soft" size="sm" color="danger">
                          No files
                        </Chip>
                      )}
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="neutral">
                        {firmDocumentFolderEntity.statistics.totalNumberOfFiles ?? 0}
                      </Chip>
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="neutral">
                        {firmDocumentFolderEntity.statistics.filesSizeInMb.toFixed(2)} MB
                      </Chip>
                    </td>

                    <td>
                      {firmDocumentFolderEntity.flagType ? (
                        <FlagTypeChip flagType={firmDocumentFolderEntity.flagType} />
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      <Typography
                        level="body-sm"
                        sx={{ whiteSpace: "normal", wordBreak: "break-word" }}
                      >
                        {firmDocumentFolderEntity.flaggedBy}
                      </Typography>
                    </td>

                    <td>
                      {firmDocumentFolderEntity.flagType == null || firmDocumentFolderEntity.flagType === FlagType.MigrationEntityBlockedByUser ? (
                        <Button
                          sx={{
                            width: "100%",
                            maxWidth: 150,
                            justifyContent: "flex-start",
                            textAlign: "left",
                          }}
                          variant="soft"
                          aria-label="Block/Unblock"
                          disabled={isDisabled}
                          size="sm"
                          color={
                            firmDocumentFolderEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser
                              ? "lime"
                              : "warning"
                          }
                          startDecorator={
                            firmDocumentFolderEntity.flagType ===
                            FlagType.MigrationEntityBlockedByUser ? (
                              <LockOpenIcon />
                            ) : (
                              <BlockIcon />
                            )
                          }
                          onClick={() => {
                            firmDocumentFolderEntity.flagType === FlagType.MigrationEntityBlockedByUser
                              ? handleBlockModal(
                                firmDocumentFolderEntity.folderPath,
                                firmDocumentFolderEntity.id,
                                false,
                                true
                              )
                              : handleBlockModal(
                                firmDocumentFolderEntity.folderPath,
                                firmDocumentFolderEntity.id,
                                true,
                                true
                              );
                          }}
                        >
                          {firmDocumentFolderEntity.flagType ===
                          FlagType.MigrationEntityBlockedByUser
                            ? "Unblock"
                            : "Block"}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      {getActiveButtonForExplorer(firmDocumentFolderEntity, isDisabled)}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      justifyContent: "flex-end",
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select
                        onChange={handleChangeRowsPerPage}
                        value={rowsPerPage}
                      >
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                      </Select>
                    </FormControl>

                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from: rows === 0 ? 0 : page * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows === -1 ? -1 : rows,
                      })}
                    </Typography>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          rows !== -1
                            ? page >= Math.ceil(rows / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Skeleton>
      </Sheet>

      {/* Block Modal */}
      <ConfirmFirmDocumentsFolderBlockUpdateModal
        open={isBlockModalOpen}
        isBlocked={isBlocked}
        folderPath={dirtyFolderPath}
        onClose={() => {
          handleBlockModal("", "", false, false);
        }}
        onConfirm={async () => {
          await firmDocumentsFoldersRepository.updateFirmDocumentsFolderBlock(dirtyFirmDocumentsFolderId, isBlocked);
          await firmDocumentsFoldersQuery.refetch();
          handleBlockModal("", "", false, false);
        }}
      />
    </React.Fragment>
  );
};

export default FirmDocumentsFoldersTable;
