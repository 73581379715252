/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Order } from "../../helpers/TableHelpers";
import { FirmDocument } from "../../models/FirmDocumentFolder/FirmDocument";
import { ClearableSelect } from "../common/ClearableSelect";
import FileExtensionsInput from "../common/FileExtensionsInput";
import MigrationStatusChip from "../common/MigrationStatusChip";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import { ActiveFirmDocumentsJobsExplorer } from "../../pages/FirmDocumentsFolderPage";
import FirmDocumentsFoldersRepository from "../../repositories/FirmDocumentsFoldersRepository";
import JobsRepository from "../../repositories/JobsRepository";
import {
  MigrationStatus,
  MigrationStatusColors,
  MigrationStatusLabels
} from "../../models/MigrationStatus";

import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Skeleton,
  Table,
  Typography
} from "@mui/joy";

import {
  Delete as DeleteIcon,
  Search as SearchIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Download as DownloadIcon
} from "@mui/icons-material";

interface FirmDocumentsTableProps {
  organizationId: string;
  firmDocumentsFolderId: string;
  activeJobsExplorer: ActiveFirmDocumentsJobsExplorer;
  newFirmDocumentsForDeletion: FirmDocument[];
  setNewFirmDocumentsForDeletion: React.Dispatch<React.SetStateAction<FirmDocument[]>>;
}

export interface FirmDocumentsTableRef {
  exportCurrentViewToCsv: () => void;
}

const FirmDocumentsTable = forwardRef<FirmDocumentsTableRef, FirmDocumentsTableProps>(({
    organizationId,
    firmDocumentsFolderId,
    activeJobsExplorer,
    newFirmDocumentsForDeletion,
    setNewFirmDocumentsForDeletion,
  },
    ref
  ) => {
  const firmDocumentsFoldersRepository = new FirmDocumentsFoldersRepository();
  const jobsRepository = new JobsRepository();
  const queryClient = useQueryClient();
  
  const [firmDocuments, setFirmDocuments] = useState<FirmDocument[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rows, setRows] = useState(0);
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<string>("status");
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [filePathFilter, setFilePathFilter] = useState<string | null>("");
  const [minSizeFilter, setMinSizeFilter] = useState<number | null>(null);
  const [maxSizeFilter, setMaxSizeFilter] = useState<number | null>(null);
  const [extensions, setExtensions] = useState<string[]>([]);
  const [extensionsSearch, setExtensionsSearch] = useState<string[]>([]);

  const firmDocumentsQuery = useQuery(
    [
      "firmDocuments",
      firmDocumentsFolderId,
      page,
      rowsPerPage,
      statusFilter,
      sort,
      order,
      extensionsSearch,
      filePathFilter,
      minSizeFilter,
      maxSizeFilter,
    ],
    async () => {
      return await firmDocumentsFoldersRepository.getFirmDocumentsForFirmDocumentsFolder(firmDocumentsFolderId, {
        descending: order === "desc",
        orderBy: sort,
        status: statusFilter ? [statusFilter] : null,
        extension: extensionsSearch,
        minFileSize: minSizeFilter,
        maxFileSize: maxSizeFilter,
        filePath: filePathFilter,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
      });
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (firmDocumentsQuery.data?.data) {
      setFirmDocuments(firmDocumentsQuery.data?.data);
      setRows(firmDocumentsQuery.data.paging?.totalCount ?? 0);
    }
  }, [firmDocumentsQuery]);

  const exportCurrentViewToCsv = async () => {
    const options = {
      descending: order === "desc",
      orderBy: sort,
      status: statusFilter ? [statusFilter] : null,
      extension: extensionsSearch,
      minFileSize: minSizeFilter,
      maxFileSize: maxSizeFilter,
      filePath: filePathFilter,
    };

    await jobsRepository.startCsvGenerationFromFirmDocuments(
      organizationId,
      firmDocumentsFolderId,
      options
    );
    queryClient.invalidateQueries("jobs");
    queryClient.invalidateQueries([
      "exportFirmDocumentsToCsv",
      organizationId,
      firmDocumentsFolderId,
    ]);
  };

  useImperativeHandle(ref, () => ({
    exportCurrentViewToCsv,
  }));

  const handleDownloadFromSharepoint = async (fileId: string) => {
    await firmDocumentsFoldersRepository.downloadSharepointFile(
      firmDocumentsFolderId,
      fileId
    );
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  
  const changeSort = (newSort: string) => {
    setPage(0);
    setSort(newSort);
  };
  
  const changeOrder = (newOrder: Order) => {
    setPage(0);
    setOrder(newOrder);
  };
  
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const handleAddFirmDocumentForDeletion = (firmDocumentEntity: FirmDocument) => {
    if (!disabledRows.has(firmDocumentEntity.id)) {
      setNewFirmDocumentsForDeletion((prevRows) => [...prevRows, firmDocumentEntity]);
    }
  };
  
  const getDisabledRows = () => {
    switch (activeJobsExplorer) {
      case ActiveFirmDocumentsJobsExplorer.DeleteFirmDocuments:
        return new Set(newFirmDocumentsForDeletion.map((c) => c.id));
    }
    return new Set<string>();
  };
  
  const handleFilePathSearch = (filePath: string) => {
    setFilePathFilter(filePath);
  };

  const handleMaxSizeFilter = (size: string) => {
    const maxSize = parseToNumberOrNull(size);
    setMaxSizeFilter(maxSize);
  };

  const handleMinSizeFilter = (size: string) => {
    const minSize = parseToNumberOrNull(size);
    setMinSizeFilter(minSize);
  };

  const parseToNumberOrNull = (value: string) => {
    return value !== "" && value !== null 
      ? Number(value) 
      : null;
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows === -1)
      return (page + 1) * rowsPerPage;
    
    return rowsPerPage === -1 
      ? rows 
      : Math.min(rows, (page + 1) * rowsPerPage);
  };

  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }
  const { isError, isFetching } = firmDocumentsQuery;

  const getActiveButtonForExplorer = (
    firmDocumentEntity: FirmDocument,
    isDisabled: boolean
  ) => {
    switch (activeJobsExplorer) {
      case ActiveFirmDocumentsJobsExplorer.DeleteFirmDocuments:
        return (
          <IconButton
            aria-label="Delete"
            disabled={isDisabled}
            size="sm"
            color="danger"
            onClick={() => handleAddFirmDocumentForDeletion(firmDocumentEntity)}
          >
            <DeleteIcon />
          </IconButton>
        );

      default:
        <></>;
    }
  };
  
  const disabledRows = getDisabledRows();

  return (
    <React.Fragment>
      
      {/*Filters*/}
      <Box
        sx={{
          borderRadius: "sm",
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        {/*Extension*/}
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for files with extension</FormLabel>
          <FileExtensionsInput
            inputLabel={extensions.length > 0 ? "" : "Extensions"}
            extensions={extensions}
            onExtensionsChange={(newExtensions) => {
              setExtensions(newExtensions);
            }}
            usePlaceholderAsLabel={true}
            maxExtensionsPerLine={null}
            endDecorator={
              <React.Fragment>
                <Divider />
                <Button
                  onClick={() => {
                    setExtensionsSearch(extensions);
                  }}
                  variant="plain"
                  startDecorator={<SearchIcon />}
                >
                  Search
                </Button>
              </React.Fragment>
            }
          />
        </FormControl>

        {/*Files min size*/}
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Files min size</FormLabel>
          <Input
            size="sm"
            placeholder="Min size"
            startDecorator={<SearchIcon />}
            onBlur={(event) => handleMinSizeFilter(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const target = event.target as HTMLInputElement;
                target.blur();
              }
            }}
            slotProps={{
              input: {
                type: "number",
                min: 0,
              },
            }}
          />
        </FormControl>

        {/*Files max size*/}
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Files max size</FormLabel>
          <Input
            size="sm"
            placeholder="Max size"
            startDecorator={<SearchIcon />}
            onBlur={(event) => handleMaxSizeFilter(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const target = event.target as HTMLInputElement;
                target.blur();
              }
            }}
            slotProps={{
              input: {
                type: "number",
                min: 0,
              },
            }}
          />
        </FormControl>

        {/*Status*/}
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Status</FormLabel>
          <ClearableSelect
            value={statusFilter}
            color={MigrationStatusColors[statusFilter as MigrationStatus]}
            onChange={(newValue) => {
              setPage(0);
              setStatusFilter(newValue);
            }}
            size="sm"
            placeholder="Filter by status"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          >
            {Object.values(MigrationStatus)
              .filter((v) => v !== MigrationStatus.InProgress)
              .map((status) => {
                const migrationStatus = status as MigrationStatus;
                return (
                  <Option
                    value={migrationStatus}
                    color={MigrationStatusColors[migrationStatus]}
                  >
                    {MigrationStatusLabels[migrationStatus]}
                  </Option>
                );
              })}
          </ClearableSelect>
        </FormControl>
        
      </Box>
      
      <Box
        sx={{
          borderRadius: "sm",
          pb: 1,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for files with path</FormLabel>
          <Input
            size="sm"
            placeholder="File path"
            startDecorator={<SearchIcon />}
            onBlur={(event) => handleFilePathSearch(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const target = event.target as HTMLInputElement;
                target.blur();
              }
            }}
          />
        </FormControl>
      </Box>
      
      {/*Table*/}
      <Sheet
        className="FirmDocumentsTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
            <tr>
              <th
                style={{
                  width: "2.5%",
                  minWidth: 28,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              ></th>
              <th
                style={{
                  width: "12%",
                  padding: "12px 6px",
                }}
              >
                File ID
              </th>
              <th
                style={{
                  width: "12%",
                  padding: "12px 6px"
                }}
              >
                <SortableTableColumnHeader
                  label={"Status"}
                  sortProperty={"status"}
                  getSort={() => sort}
                  setSort={(newSort) => changeSort(newSort)}
                  getOrder={() => order}
                  setOrder={(newOrder) => changeOrder(newOrder)}
                />
              </th>
              <th
                style={{
                  width: "50%",
                  padding: "12px 6px"
                }}
              >
                File Path
              </th>
              <th style={{width: "12%", padding: "12px 6px"}}>
                <SortableTableColumnHeader
                  label={"Size (MB)"}
                  sortProperty={"fileSize"}
                  getSort={() => sort}
                  setSort={(newSort) => changeSort(newSort)}
                  getOrder={() => order}
                  setOrder={(newOrder) => changeOrder(newOrder)}
                />
              </th>

              <th
                style={{
                  width: "8%",
                  padding: "12px 6px"
                }}
              >
                Extension
              </th>
              <th
                style={{
                  width: "5%",
                  minWidth: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              ></th>
              <th
                style={{
                  width: "6%",
                  minWidth: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              ></th>
            </tr>
            </thead>
            <tbody>
            {firmDocuments.map((firmDocument: FirmDocument) => {
              const isDisabled = disabledRows.has(firmDocument.id);
              return (
                <tr key={firmDocument.id}>
                    <td style={{textAlign: "center"}}></td>
                    <td>
                      <Typography level="body-sm">{firmDocument.id}</Typography>
                    </td>
                    <td>
                      <MigrationStatusChip status={firmDocument.status}/>
                    </td>
                    <td>
                      <Typography
                        level="body-sm"
                        sx={{wordBreak: "break-word"}}
                      >
                        {firmDocument.filePath}
                      </Typography>
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="neutral">
                        {firmDocument.fileSizeInMb.toFixed(2)}
                      </Chip>
                    </td>
                    <td>
                      <Chip variant="soft" size="sm" color="primary">
                        {firmDocument.extension ?? "-"}
                      </Chip>
                    </td>
                    <td>
                      {getActiveButtonForExplorer(firmDocument, isDisabled)}
                    </td>
                    <td>
                      {firmDocument.status === MigrationStatus.Finished ? (
                        <IconButton
                          aria-label="Download"
                          disabled={isDisabled}
                          size="sm"
                          color="primary"
                          onClick={() =>
                            handleDownloadFromSharepoint(firmDocument.id)
                          }
                        >
                          <DownloadIcon/>
                        </IconButton>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
            <tr>
              <td colSpan={8}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <FormControl orientation="horizontal" size="sm">
                    <FormLabel>Rows per page:</FormLabel>
                    <Select
                      onChange={handleChangeRowsPerPage}
                      value={rowsPerPage}
                      >
                        <Option value={100}>100</Option>
                        <Option value={500}>500</Option>
                        <Option value={1000}>1000</Option>
                      </Select>
                    </FormControl>
                    <Typography textAlign="center" sx={{ minWidth: 80 }}>
                      {labelDisplayedRows({
                        from: rows === 0 ? 0 : page * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows === -1 ? -1 : rows,
                      })}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          rows !== -1
                            ? page >= Math.ceil(rows / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Skeleton>
      </Sheet>
    </React.Fragment>
  );
});

export default FirmDocumentsTable;
