import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
  TabPanel,
  TabList,
  tabClasses,
  Divider,
} from "@mui/joy";
import { useParams } from "react-router-dom";
import ProvidersTable from "../components/Providers/ProvidersTable";
import DeleteProvidersTable from "../components/Providers/DeleteProvidersTable";
import { useState } from "react";
import { DeleteProviderRequest } from "../models/Jobs/JobRequest";
import CreateExportProvidersToCsvTable from "../components/Providers/CreateExportProvidersToCsvTable";
import { Provider } from "../models/Provider";
import ExportProvidersToCsvStatusTable from "../components/Providers/ExportProvidersToCsvStatusTable";

export enum ActiveProviderJobsExplorer {
  DeleteProviders = 0,
  ExportCsv = 1,
}

const ProvidersPage = () => {
  const { organizationId } = useParams();
  const [newProvidersForDeletion, setNewProvidersForDeletion] = useState<
    DeleteProviderRequest[]
  >([]);
  const [newProvidersForExport, setNewProvidersForExport] = useState<
    Provider[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<ActiveProviderJobsExplorer>(
    ActiveProviderJobsExplorer.DeleteProviders
  );
  return (
    <Stack maxHeight={"92vh"}>
      <Box
        sx={{
          display: "flex",
          my: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Typography level="h2">Providers</Typography>
      </Box>
      <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
        <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
          <Card>
            <ProvidersTable
              organizationId={organizationId ?? ""}
              activeJobsExplorer={selectedTab}
              newProvidersForDeletion={newProvidersForDeletion}
              setNewProvidersForDeletion={setNewProvidersForDeletion}
              newProvidersForExport={newProvidersForExport}
              setNewProvidersForExport={setNewProvidersForExport}
            />
          </Card>
        </Grid>
        <Grid
          xs={12}
          lg={3}
          display={"flex"}
          maxHeight={"100%"}
          height={"100%"}
        >
          <Card sx={{ width: "100%", padding: 0 }}>
            <Tabs
              value={selectedTab}
              sx={{
                maxHeight: "100%",
                borderRadius: "sm",
                overflow: "auto",
                height: "100%",
              }}
              onChange={(event, newValue) =>
                setSelectedTab(newValue as ActiveProviderJobsExplorer)
              }
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "md",
                    [`&[aria-selected="true"]`]: {
                      bgcolor: "background.surface",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Delete providers
                </Tab>
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Export csv
                </Tab>
              </TabList>
              <TabPanel
                value={ActiveProviderJobsExplorer.DeleteProviders}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Card
                  sx={{
                    padding: 0,
                    border: 0,
                    width: "100%",
                    height: "100%",
                    maxHeight: "100%",
                  }}
                >
                  <DeleteProvidersTable
                    organizationId={organizationId ?? ""}
                    newProvidersForDeletion={newProvidersForDeletion}
                    setNewProvidersForDeletion={setNewProvidersForDeletion}
                  />
                </Card>
              </TabPanel>
              <TabPanel
                value={ActiveProviderJobsExplorer.ExportCsv}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  height: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  {newProvidersForExport.length > 0 && (
                    <>
                      <Divider sx={{ paddingTop: 2 }}>
                        <b>New</b>
                      </Divider>
                      <CreateExportProvidersToCsvTable
                        organizationId={organizationId ?? ""}
                        newProvidersForExport={newProvidersForExport}
                        setNewProvidersForExport={setNewProvidersForExport}
                      />
                      <Divider>
                        <b>Existing exports</b>
                      </Divider>
                    </>
                  )}
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    <ExportProvidersToCsvStatusTable
                      organizationId={organizationId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ProvidersPage;
