import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Tab,
  tabClasses,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ProviderFilesTable, {
  ProviderFilesTableRef,
} from "../components/Provider/ProviderFilesTable";
import MigrationStatusChip from "../components/common/MigrationStatusChip";
import { ProviderWithStatistics } from "../models/Provider";
import {
  MigrationStatus,
  MigrationStatusColors,
} from "../models/MigrationStatus";
import ProvidersRepository from "../repositories/ProvidersRepository";
import React from "react";
import DeleteProviderFilesStatusTable from "../components/Provider/DeleteProviderFilesStatusTable";
import { ProviderFile } from "../models/ProviderFile";
import CreateDeleteProviderFilesRequestTable from "../components/Provider/CreateDeleteProviderFilesRequestTable";
import ExportProviderFilesToCsvStatusTable from "../components/Provider/ExportProviderFilesToCsvStatusTable";
import ConfirmProviderFilesExportToCsvModal from "../components/Provider/ConfirmProviderFilesExportToCsvModal";
export enum ActiveProviderFilesJobsExplorer {
  DeleteProviderFiles = 0,
  ExportCsv = 1,
}
const ProviderPage = () => {
  const { organizationId, providerId } = useParams();
  const providersRepository = new ProvidersRepository();
  const [providerEntity, setProvider] = useState<
    ProviderWithStatistics | undefined
  >(undefined);

  const [isConfirmExportModalOpen, setIsConfirmExportModalOpen] =
    React.useState<boolean>(false);
  const [newProviderFilesForDeletion, setNewProviderFilesForDeletion] =
    useState<ProviderFile[]>([]);
  const [selectedTab, setSelectedTab] =
    useState<ActiveProviderFilesJobsExplorer>(
      ActiveProviderFilesJobsExplorer.DeleteProviderFiles
    );
  const providerQuery = useQuery(
    ["provider", providerId],
    async () => {
      return await providersRepository.getProvider(providerId ?? "");
    },
    {
      cacheTime: 2 * 60 * 1000,
      refetchInterval: 2 * 60 * 1000,
      refetchOnMount: true,
    }
  );
  const providerFilesTableRef = useRef<ProviderFilesTableRef>(null);

  useEffect(() => {
    if (providerQuery.data) {
      setProvider(providerQuery.data);
    }
  }, [providerQuery.data, providerId]);

  const { isLoading, isError } = providerQuery;
  if (isLoading || isError || !!!providerEntity) {
    return <></>;
  }

  const startCurrentViewExportToCsv = () => {
    providerFilesTableRef.current?.exportCurrentViewToCsv();
  };

  const migratedPercentage =
    providerEntity.statistics.totalNumberOfFiles > 0 &&
    providerEntity.statistics.filesSizeInMb > 0
      ? (providerEntity.statistics.filesMigrated /
          providerEntity.statistics.totalNumberOfFiles) *
        100
      : 0;

  return (
    <Stack maxHeight={"92vh"}>
      <Box
        sx={{
          width: "100%",
          my: 1,
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <Stack>
          <Typography level="h2">
            Provider {providerEntity.providerNameId}{" "}
            <MigrationStatusChip status={providerEntity.status} size={"md"} />
          </Typography>
          <Stack
            width="100%"
            flex={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Stack
              flex={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Started</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotStarted]}
                >
                  {providerEntity.statistics.totalNumberOfFiles -
                    (providerEntity.statistics.filesMigrated +
                      providerEntity.statistics.filesInterrupted +
                      providerEntity.statistics.filesNotFound)}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Migrated</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Finished]}
                >
                  {providerEntity.statistics.filesMigrated}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Interrupted</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.Interrupted]}
                >
                  {providerEntity.statistics.filesInterrupted}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Not Found</FormLabel>
                <Chip
                  variant="soft"
                  size="sm"
                  color={MigrationStatusColors[MigrationStatus.NotFound]}
                >
                  {providerEntity.statistics.filesNotFound}
                </Chip>
              </FormControl>

              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Files Count</FormLabel>
                <Chip variant="soft" size="sm" color="neutral">
                  {providerEntity.statistics.totalNumberOfFiles}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Size (MB)</FormLabel>
                <Chip variant="soft" size="sm" color="primary">
                  {providerEntity.statistics.filesSizeInMb.toFixed(2)}
                </Chip>
              </FormControl>
              <FormControl size="sm" orientation="horizontal">
                <FormLabel>Progress</FormLabel>
                <Chip
                  sx={{ textAlign: "center" }}
                  variant="soft"
                  size="sm"
                  color={migratedPercentage > 99.99 ? "success" : "primary"}
                >
                  {migratedPercentage % 1 > 0.99 ||
                  migratedPercentage % 1 < 0.01
                    ? `${migratedPercentage.toFixed(0)}%`
                    : `${migratedPercentage.toFixed(2)}%`}
                </Chip>
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Grid container spacing={2} display={"inline-flex"} maxHeight={"100%"}>
        <Grid xs={12} lg={9} display={"flex"} maxHeight={"100%"}>
          <Card>
            <ProviderFilesTable
              ref={providerFilesTableRef}
              organizationId={organizationId ?? ""}
              providerId={providerId ?? ""}
              activeJobsExplorer={selectedTab}
              newProviderFilesForDeletion={newProviderFilesForDeletion}
              setNewProvidersFilesForDeletion={setNewProviderFilesForDeletion}
            />
          </Card>
        </Grid>
        <Grid
          xs={12}
          lg={3}
          display={"flex"}
          maxHeight={"100%"}
          height={"100%"}
        >
          <Card sx={{ width: "100%", padding: 0 }}>
            <Tabs
              value={selectedTab}
              sx={{
                maxHeight: "100%",
                borderRadius: "sm",
                overflow: "auto",
                height: "100%",
              }}
              onChange={(event, newValue) =>
                setSelectedTab(newValue as ActiveProviderFilesJobsExplorer)
              }
            >
              <TabList
                disableUnderline
                tabFlex={1}
                sx={{
                  [`& .${tabClasses.root}`]: {
                    fontSize: "sm",
                    fontWeight: "md",
                    [`&[aria-selected="true"]`]: {
                      bgcolor: "background.surface",
                    },
                    [`&.${tabClasses.focusVisible}`]: {
                      outlineOffset: "-4px",
                    },
                  },
                }}
              >
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Delete files
                </Tab>
                <Tab disableIndicator variant="soft" sx={{ flexGrow: 1 }}>
                  Export csv
                </Tab>
              </TabList>
              <TabPanel
                value={ActiveProviderFilesJobsExplorer.DeleteProviderFiles}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  {newProviderFilesForDeletion.length > 0 && (
                    <>
                      <Divider sx={{ paddingTop: 2 }}>
                        <b>New</b>
                      </Divider>
                      <CreateDeleteProviderFilesRequestTable
                        organizationId={organizationId ?? ""}
                        providerId={providerId ?? ""}
                        providerNameId={providerEntity.providerNameId}
                        newFilesForDeletion={newProviderFilesForDeletion}
                        setNewProviderFilesForDeletion={
                          setNewProviderFilesForDeletion
                        }
                      />
                      <Divider>
                        <b>Deletion status</b>
                      </Divider>
                    </>
                  )}
                  <Card
                    sx={{
                      padding: 0,
                      border: 0,
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    <DeleteProviderFilesStatusTable
                      organizationId={organizationId ?? ""}
                      providerId={providerId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
              <TabPanel
                value={ActiveProviderFilesJobsExplorer.ExportCsv}
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100%",
                  paddingTop: 0,
                }}
              >
                <Stack height={"100%"} spacing={2}>
                  <Card
                    sx={{
                      padding: "1em 0 0 0",
                      border: 0,
                      width: "100%",
                      flex: 1,
                    }}
                  >
                    <Button onClick={() => setIsConfirmExportModalOpen(true)}>
                      Export current files view
                    </Button>
                    <ExportProviderFilesToCsvStatusTable
                      organizationId={organizationId ?? ""}
                      providerId={providerId ?? ""}
                    />
                  </Card>
                </Stack>
              </TabPanel>
            </Tabs>
          </Card>
        </Grid>
      </Grid>

      {/* Confirm CSV export modal */}
      {isConfirmExportModalOpen ? (
        <ConfirmProviderFilesExportToCsvModal
          open={isConfirmExportModalOpen}
          onClose={() => {
            setIsConfirmExportModalOpen(false);
          }}
          onConfirm={async () => {
            startCurrentViewExportToCsv();
            setIsConfirmExportModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default ProviderPage;
