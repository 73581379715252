import * as React from "react";
import {ImplementationType} from "../../models/OrganizationConfiguration/ImplementationType";

import { 
  Option,
  Select
} from "@mui/joy";

interface ImplementationTypeSelectProps {
  value: ImplementationType | undefined;
  onChange: (value: ImplementationType | undefined) => void;
  required: boolean;
}

const ImplementationTypeSelect: React.FC<ImplementationTypeSelectProps> = ({
  value,
  onChange,
  required,
}) => {
  return (
    <Select
      required={required}
      id="implementation-type"
      name="implementationType"
      value={value || ""}
      onChange={(event, value) => onChange(value as ImplementationType)}
    >
      <Option value={ImplementationType.AzureBlob}>Azure Blob</Option>
      <Option value={ImplementationType.Filevine}>Filevine</Option>
      <Option value={ImplementationType.Dropbox}>Dropbox</Option>
      <Option value={ImplementationType.Needles4}>Needles 4 - Sybase</Option>
      <Option value={ImplementationType.Needles5SQL}>Needles 5 - SQL</Option>
      <Option value={ImplementationType.Needles5Sybase}>Needles 5 - Sybase</Option>
      <Option value={ImplementationType.TrialWorks}>Trialworks</Option>
      <Option value={ImplementationType.DocsToNeos}>Docs To Neos</Option>
    </Select>
  );
};

export default ImplementationTypeSelect;
