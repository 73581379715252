import React from "react";
import LabelValuePair from "../common/LabelValuePair";
import {
  AuditLog,
  AuditLogType,
  AuditLogTypeLabels,
} from "../../models/AuditLog";

import {
  Modal,
  ModalDialog,
  DialogTitle,
  Divider,
  DialogContent,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/joy";

import CloseIcon from "@mui/icons-material/Close";

const ViewExportFilesAuditLogDetailsModal: React.FC<{
  auditLog: AuditLog | null;
  open: boolean;
  onClose: () => void;
  organizationId: string;
}> = ({ auditLog, open, onClose, organizationId }) => {
  const getLabel = (eventType: AuditLogType): string => {
    switch (eventType) {
      case AuditLogType.CaseFilesExport:
        return "Case:";
      case AuditLogType.FirmDocumentsExport:
        return "Firm Documents Folder:";
      case AuditLogType.ProviderFilesExport:
        return "Provider:";
      default:
        return "";
    }
  };

  const generateDynamicLink = (entityId: string | null, type: AuditLogType) => {
    if (entityId === null) return `/organization/${organizationId}/audit-logs`;

    switch (type) {
      case AuditLogType.CaseFilesExport:
        return `/organization/${organizationId}/case/${entityId}`;
      case AuditLogType.FirmDocumentsExport:
        return `/organization/${organizationId}/firm-documents-folders/${entityId}`;
      case AuditLogType.ProviderFilesExport:
        return `/organization/${organizationId}/provider/${entityId}`;
      default:
        return `/organization/${organizationId}/audit-logs`;
    }
  };

  const getEntityId = (
    eventType: AuditLogType,
    additionalData: string | null
  ): string | null => {
    if (!auditLog) return null;

    if (!additionalData) return null;

    const additionalDataAsJson = JSON.parse(additionalData);
    switch (eventType) {
      case AuditLogType.CaseFilesExport:
        return additionalDataAsJson.CaseId;
      case AuditLogType.FirmDocumentsExport:
        return additionalDataAsJson.FirmDocumentsFolderId;
      case AuditLogType.ProviderFilesExport:
        return additionalDataAsJson.ProviderId;
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        width: {
          xs: "100vw",
          lg: "calc(100vw + 15vw)",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {auditLog ? (
        <ModalDialog
          variant="outlined"
          sx={{ width: "65%", maxHeight: "90vh", overflow: "hidden" }}
        >
          <DialogTitle>
            {AuditLogTypeLabels[auditLog.eventType]}
            <IconButton
              variant="plain"
              color="neutral"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 12,
                top: 12,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent sx={{ m: 0 }}>
            <Stack direction="column" gap={1}>
              <Typography level="body-sm" sx={{ fontWeight: "bold" }}>
                {getLabel(auditLog.eventType)}
              </Typography>
              {auditLog.additionalData ? (
                (() => {
                  try {
                    const entityId = getEntityId(
                      auditLog.eventType,
                      auditLog.additionalData
                    );
                    console.log(entityId);

                    if (entityId) {
                      return (
                        <Typography level="body-sm">
                          <Link
                            href={generateDynamicLink(
                              entityId,
                              auditLog.eventType
                            )}
                            underline="hover"
                          >
                            {entityId}
                          </Link>
                        </Typography>
                      );
                    }

                    return (
                      <Typography level="body-sm">
                        Invalid data format
                      </Typography>
                    );
                  } catch (error) {
                    return (
                      <Typography level="body-sm">
                        Failed to parse additionalData
                      </Typography>
                    );
                  }
                })()
              ) : (
                <></>
              )}
            </Stack>
          </DialogContent>

          <Divider />

          <LabelValuePair
            label="Peroformed By"
            value={auditLog.performerEmailAddress}
          />

          <LabelValuePair label="Event Date" value={auditLog.eventDate} />
        </ModalDialog>
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default ViewExportFilesAuditLogDetailsModal;
