import React, { useEffect } from "react";
import { ClientConfiguration } from "../../models/ClientConfiguration/ClientConfiguration";
import { RuntimeScheduleConfiguration } from "../../models/ClientConfiguration/RuntimeScheduleConfiguration";
import LoggingVerbositySelect from "./LoggingVerbositySelect";
import RuntimeScheduleControl from "./RuntimeScheduleControl";
import ServiceVersionSelect from "./ServiceVersionSelect";
import { ImplementationType } from "../../models/OrganizationConfiguration/ImplementationType";
import { FormControl, FormLabel, Grid, Input, Stack, Switch } from "@mui/joy";
import OutlinedDiv from "../common/OutlinedDiv";

interface ClientSettingsSectionProps {
  implementationType: ImplementationType;
  value: ClientConfiguration;
  onChange: (updatedConfig: ClientConfiguration) => void;
}

const ClientSettingsSection: React.FC<ClientSettingsSectionProps> = ({
  implementationType,
  value,
  onChange,
}) => {
  const defaultDbConnectionStrings: { [key in ImplementationType]: string } = {
    [ImplementationType.Needles4]: "DSN=Needles",
    [ImplementationType.Needles5Sybase]: "DSN=Needles",
    [ImplementationType.Needles5SQL]: "Server=;Database=Needles;User id=dba;Password=sql;",
    [ImplementationType.TrialWorks]: "Data Source=localhost;Initial Catalog=TrialWorks;Integrated Security=True;",
    [ImplementationType.AzureBlob]: "",
    [ImplementationType.Filevine]: "",
    [ImplementationType.Dropbox]: "",
    [ImplementationType.DocsToNeos]: "",
  };

  useEffect(() => {
    if (value.dbConnectionString === undefined && implementationType != null) {
      value.dbConnectionString = defaultDbConnectionStrings[implementationType];
    }
  }, [implementationType]);

  const handleServiceVersionChange = (version: string) => {
    onChange({
      ...value,

      serviceVersion: version,
    });
  };

  const handleLoggingVerbosityChange = (sendDebugLogs: boolean) => {
    onChange({
      ...value,
      sendDebugLogs: sendDebugLogs,
    });
  };

  const handleRuntimeSchedulesChange = (
    runtimeSchedules: RuntimeScheduleConfiguration[]
  ) => {
    onChange({
      ...value,
      runtimeSchedules: runtimeSchedules,
    });
  };

  const handleDbConnectionStringChange = (newConnectionString: string) => {
    onChange({
      ...value,
      dbConnectionString: newConnectionString,
    });
  };

  return (
    <Grid container spacing={2} height={"100%"} width={"100%"}>
      {implementationType !== ImplementationType.DocsToNeos &&
        implementationType !== ImplementationType.Filevine &&
        implementationType !== ImplementationType.Dropbox && (
          <Grid xs={12}>
            <FormControl>
              <FormLabel>DB Connection String</FormLabel>
              <Input
                onChange={(event) =>
                  handleDbConnectionStringChange(event.target.value)
                }
                fullWidth
                value={value.dbConnectionString || ""}
              />
            </FormControl>
          </Grid>
        )}

      <Grid xs={12}>
        <Grid container spacing={1}>
          <Grid xs={6} minWidth={"50%"}>
            <ServiceVersionSelect
              value={value.serviceVersion || ""}
              onChange={handleServiceVersionChange}
            />
          </Grid>
          <Grid xs={6} minWidth={"50%"}>
            <LoggingVerbositySelect
              value={value.sendDebugLogs}
              onChange={handleLoggingVerbosityChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} minWidth={"100%"}>
        <RuntimeScheduleControl
          runtimeSchedules={value.runtimeSchedules || []}
          onChange={handleRuntimeSchedulesChange}
        />
      </Grid>
      <Grid xs={12}>
        <OutlinedDiv label="Cache Settings">
          <Stack
            height={"100%"}
            spacing={2}
            sx={{ display: "flex", flexDirection: "column", mt: 0.5 }}
            justifyContent={"space-between"}
          >
            <FormControl orientation="horizontal">
              <FormLabel>Use Cache</FormLabel>
              <Switch
                checked={value.useCache}
                onChange={(event) =>
                  onChange({
                    ...value,
                    useCache: event.target.checked,
                  })
                }
              />
            </FormControl>

            <FormControl disabled={!value.useCache} orientation="horizontal">
              <FormLabel>Use Deltas</FormLabel>
              <Switch
                checked={value.useDeltas}
                onChange={(event) =>
                  onChange({
                    ...value,
                    useDeltas: event.target.checked,
                  })
                }
              />
            </FormControl>
            
            <FormControl disabled={!value.useCache} orientation="horizontal">
              <FormLabel>Rebuild Cache</FormLabel>
              <Switch
                checked={value.rebuildCache}
                onChange={(event) =>
                  onChange({
                    ...value,
                    rebuildCache: event.target.checked,
                  })
                }
              />
            </FormControl>
            
          </Stack>
        </OutlinedDiv>
      </Grid>
    </Grid>
  );
};

export default ClientSettingsSection;
