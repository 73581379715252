/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { compareBy, Order, stableSort } from "../../helpers/TableHelpers";

import { ClearableSelect } from "../common/ClearableSelect";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";

import { IconButton, Skeleton } from "@mui/joy";
import JobRequestStatusChip from "../Jobs/JobRequestStatusChip";
import {
  JobRequestStatus,
  JobRequestStatusColors,
  JobRequestStatusLabels,
} from "../../models/Jobs/JobRequestStatus";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DeleteProviderFilesRequest,
  JobRequest,
} from "../../models/Jobs/JobRequest";
import AbortJobModal from "../Jobs/AbortJobModal";
import JobsRepository from "../../repositories/JobsRepository";
import { Delete } from "@mui/icons-material";
import JobInfoView from "../Jobs/JobInfoView";
interface DeleteProviderFilesStatusTableProps {
  organizationId: string;
  providerId: string;
}

const DeleteProviderFilesStatusTable: React.FC<
  DeleteProviderFilesStatusTableProps
> = ({ organizationId, providerId }) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [sort, setSort] =
    React.useState<keyof DeleteProviderFilesRequest>("id");
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);

  const [abortJob, setAbortJob] = useState<JobRequest | null>(null);

  const [providerFilesDeletionStatus, setProviderFilesDeletionStatus] =
    useState<DeleteProviderFilesRequest[] | null>(null);
  const jobsRepository = new JobsRepository();
  const queryClient = useQueryClient();
  const statusQuery = useQuery(
    ["deleteProviderFiles", organizationId, providerId],
    async () => {
      const status = await jobsRepository.getProviderFilesDeletionStatus(
        organizationId,
        providerId
      );
      return status;
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1, // Refetch data every 2 minutes
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );
  useEffect(() => {
    if (statusQuery.data) {
      setProviderFilesDeletionStatus(statusQuery.data);
    }
  }, [statusQuery]);

  const changeSort = (newSort: keyof DeleteProviderFilesRequest) => {
    setSort(newSort);
  };
  const changeOrder = (newOrder: Order) => {
    setOrder(newOrder);
  };

  const { isError, isFetching } = statusQuery;
  const filteredJobs = (providerFilesDeletionStatus || []).filter((job) => {
    const statusMatches = statusFilter == null || job.status === statusFilter;
    return statusMatches;
  });

  const sortedJobs = sort
    ? stableSort<DeleteProviderFilesRequest>(
        filteredJobs,
        compareBy(order, sort)
      )
    : filteredJobs;

  return (
    <React.Fragment>
      <Box
        width={"100%"}
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Status</FormLabel>
          <ClearableSelect
            value={statusFilter}
            color={JobRequestStatusColors[statusFilter as JobRequestStatus]}
            onChange={(newValue) => {
              setStatusFilter(newValue);
            }}
            size="sm"
            placeholder="Filter by status"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          >
            {Object.values(JobRequestStatus)
              .filter((status) => status !== JobRequestStatus.New)
              .map((status, idx) => {
                const jobStatus = status as JobRequestStatus;
                return (
                  <Option
                    key={idx}
                    value={jobStatus}
                    color={JobRequestStatusColors[jobStatus]}
                  >
                    {JobRequestStatusLabels[jobStatus]}
                  </Option>
                );
              })}
          </ClearableSelect>
        </FormControl>
      </Box>

      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "55%",
                    padding: "12px 6px",
                  }}
                ></th>

                <th style={{ width: "30%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "15%", padding: "12px 6px" }}></th>
              </tr>
            </thead>

            <tbody>
              {sortedJobs.map((deleteProviderFilesJob) => {
                const canEdit =
                  deleteProviderFilesJob.status === JobRequestStatus.Pending ||
                  deleteProviderFilesJob.status === JobRequestStatus.Completed;
                return (
                  <tr key={deleteProviderFilesJob.id}>
                    <td>
                      <JobInfoView jobRequest={deleteProviderFilesJob} />
                    </td>

                    <td>
                      <JobRequestStatusChip
                        status={deleteProviderFilesJob.status}
                      />
                    </td>
                    {canEdit ? (
                      <td>
                        <IconButton
                          aria-label="Cancel"
                          size="sm"
                          color="danger"
                          onClick={() => {
                            setAbortJob(deleteProviderFilesJob);
                          }}
                        >
                          {deleteProviderFilesJob.status ===
                          JobRequestStatus.Completed ? (
                            <Delete />
                          ) : (
                            <CancelIcon />
                          )}{" "}
                        </IconButton>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Skeleton>
      </Sheet>
      {abortJob ? (
        <AbortJobModal
          open={abortJob !== null}
          jobRequest={abortJob}
          onClose={() => {
            setAbortJob(null);
          }}
          onConfirm={async () => {
            await jobsRepository.deleteJob(abortJob.id);
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries([
              "deleteProviderFiles",
              organizationId,
              providerId,
            ]);
            if (abortJob.status !== JobRequestStatus.Completed) {
              queryClient.invalidateQueries(["providerFiles", providerId]);
            }
            setAbortJob(null);
          }}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default DeleteProviderFilesStatusTable;
