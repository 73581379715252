/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { compareBy, Order, stableSort } from "../../helpers/TableHelpers";
import { ClearableSelect } from "../common/ClearableSelect";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import JobRequestStatusChip from "../Jobs/JobRequestStatusChip";
import AbortJobModal from "../Jobs/AbortJobModal";
import JobInfoView from "../Jobs/JobInfoView";
import JobsRepository from "../../repositories/JobsRepository";
import {
  JobRequestStatus,
  JobRequestStatusColors,
  JobRequestStatusLabels
} from "../../models/Jobs/JobRequestStatus";
import {
  DeleteFirmDocumentsRequest,
  JobRequest
} from "../../models/Jobs/JobRequest";

import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Sheet,
  Skeleton,
  Table
} from "@mui/joy";

import { 
  Cancel as CancelIcon, 
  Delete as DeleteIcon
} from "@mui/icons-material";

interface DeleteFirmDocumentsStatusTableProps {
  organizationId: string;
  firmDocumentsFolderId: string;
}

const DeleteFirmDocumentsStatusTable: React.FC<DeleteFirmDocumentsStatusTableProps> = ({
  organizationId,
  firmDocumentsFolderId,
}) => {
  const jobsRepository = new JobsRepository();
  const queryClient = useQueryClient();
  
  const [order, setOrder] = React.useState<Order>("desc");
  const [sort, setSort] = React.useState<keyof DeleteFirmDocumentsRequest>("id");
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [abortJob, setAbortJob] = useState<JobRequest | null>(null);
  const [firmDocumentsDeletionStatus, setFirmDocumentsDeletionStatus] = useState<DeleteFirmDocumentsRequest[] | null>(null);

  const statusQuery = useQuery(
    ["deleteFirmDocuments", organizationId, firmDocumentsFolderId],
    async () => {
      return await jobsRepository.getFirmDocumentsDeletionStatus(
        organizationId,
        firmDocumentsFolderId
      );
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );
  
  useEffect(() => {
    if (statusQuery.data)
      setFirmDocumentsDeletionStatus(statusQuery.data);
  }, [statusQuery]);

  const changeSort = (newSort: keyof DeleteFirmDocumentsRequest) => {
    setSort(newSort);
  };
  
  const changeOrder = (newOrder: Order) => {
    setOrder(newOrder);
  };

  const { isError, isFetching } = statusQuery;
  
  const filteredJobs = (firmDocumentsDeletionStatus || []).filter((job) => {
    return statusFilter == null || job.status === statusFilter;
  });

  const sortedJobs = sort
    ? stableSort<DeleteFirmDocumentsRequest>(filteredJobs, compareBy(order, sort))
    : filteredJobs;

  return (
    <React.Fragment>
      <Box
        width={"100%"}
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          pb: 1,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Status</FormLabel>
          <ClearableSelect
            value={statusFilter}
            color={JobRequestStatusColors[statusFilter as JobRequestStatus]}
            onChange={(newValue) => {
              setStatusFilter(newValue);
            }}
            size="sm"
            placeholder="Filter by status"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          >
            {Object.values(JobRequestStatus)
              .filter((status) => status !== JobRequestStatus.New)
              .map((status, idx) => {
                const jobStatus = status as JobRequestStatus;
                return (
                  <Option
                    key={idx}
                    value={jobStatus}
                    color={JobRequestStatusColors[jobStatus]}
                  >
                    {JobRequestStatusLabels[jobStatus]}
                  </Option>
                );
              })}
          </ClearableSelect>
        </FormControl>
      </Box>

      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "55%",
                    padding: "12px 6px",
                  }}
                ></th>

                <th style={{ width: "30%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th style={{ width: "15%", padding: "12px 6px" }}></th>
              </tr>
            </thead>

            <tbody>
              {sortedJobs.map((deleteFirmDocumentsJob) => {
                const canEdit = deleteFirmDocumentsJob.status === JobRequestStatus.Pending 
                  || deleteFirmDocumentsJob.status === JobRequestStatus.Completed;
                
                return (
                  <tr key={deleteFirmDocumentsJob.id}>
                    <td>
                      <JobInfoView jobRequest={deleteFirmDocumentsJob} />
                    </td>

                    <td>
                      <JobRequestStatusChip
                        status={deleteFirmDocumentsJob.status}
                      />
                    </td>
                    {canEdit ? (
                      <td>
                        <IconButton
                          aria-label="Cancel"
                          size="sm"
                          color="danger"
                          onClick={() => {
                            setAbortJob(deleteFirmDocumentsJob);
                          }}
                        >
                          {deleteFirmDocumentsJob.status ===
                          JobRequestStatus.Completed ? (
                            <DeleteIcon />
                          ) : (
                            <CancelIcon />
                          )}{" "}
                        </IconButton>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Skeleton>
      </Sheet>
      
      {abortJob ? (
        <AbortJobModal
          open={abortJob !== null}
          jobRequest={abortJob}
          onClose={() => {
            setAbortJob(null);
          }}
          onConfirm={async () => {
            await jobsRepository.deleteJob(abortJob.id);
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries([
              "deleteFirmDocuments",
              organizationId,
              firmDocumentsFolderId,
            ]);
            if (abortJob.status !== JobRequestStatus.Completed) {
              queryClient.invalidateQueries(["firmDocuments", firmDocumentsFolderId]);
            }
            setAbortJob(null);
          }}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default DeleteFirmDocumentsStatusTable;
