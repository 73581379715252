import {
  DeleteCaseFilesRequest,
  DeleteCaseRequest,
  DeleteFirmDocumentsFolderRequest,
  DeleteFirmDocumentsRequest,
  DeleteIntakeFilesRequest,
  DeleteIntakeRequest,
  DeleteProviderFilesRequest,
  DeleteProviderRequest,
  ExportCaseFilesToCsvRequest,
  ExportCasesToCsvRequest,
  ExportFirmDocumentsFoldersToCsvRequest,
  ExportFirmDocumentsToCsvRequest,
  ExportIntakesToCsvRequest,
  ExportProviderFilesToCsvRequest,
  ExportProvidersToCsvRequest,
  JobRequest,
  transformJobRequest,
} from "../models/Jobs/JobRequest";
import useApi from "../services/ApiService";
import { GetCaseFilesForCaseQueryOptions } from "./CasesRepository";
import { GetFirmDocumentsForFirmDocumentsFolderQueryOptions } from "./FirmDocumentsFoldersRepository";
import { GetProviderFilesForProviderQueryOptions } from "./ProvidersRepository";

export class JobsRepository {
  private api = useApi();
  async getJobs(): Promise<JobRequest[]> {
    try {
      const response = await this.api.get("/api/jobs/all");
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(`Failed to get jobs: ${error}`);
    }
  }
  async deleteJob(jobId: string): Promise<void> {
    try {
      await this.api.delete(`/api/jobs/${jobId}`);
    } catch (error) {
      throw new Error(`Failed to abort job: ${error}`);
    }
  }

  // Case methods
  async getCasesDeletionStatus(
    organizationId: string
  ): Promise<DeleteCaseRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/cases/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch cases deletion status for organization with id: ${organizationId}`
      );
    }
  }
  async startCasesDeletion(
    organizationId: string,
    caseIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/cases/delete`,
        {
          caseIds: caseIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(`Failed to start cases deletion with ids: ${caseIds}`);
    }
  }
  async getCaseFilesDeletionStatus(
    organizationId: string,
    caseId: string
  ): Promise<DeleteCaseFilesRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/case/${caseId}/files/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch case files deletion status for organization with id: ${organizationId}`
      );
    }
  }
  async startCaseFilesDeletion(
    organizationId: string,
    caseId: string,
    fileIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/case/${caseId}/files/delete`,
        {
          fileIds: fileIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start case files deletion with ids: ${fileIds}`
      );
    }
  }
  async getCasesCsvGenerationStatus(
    organizationId: string
  ): Promise<ExportCasesToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/cases/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch csv generation from cases status for organization with id: ${organizationId}`
      );
    }
  }
  async startCsvGenerationFromCases(
    organizationId: string,
    cases: { id: string; caseNumber: string }[],
    exportFilesData: boolean
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/cases/csv`,
        {
          cases: cases,
          exportFilesData: exportFilesData,
        }
      );
      return response.data;
    } catch (error) {
      const caseIds = cases.map((c) => c.id).join(", ");
      throw new Error(
        `Failed to start CSV generation from cases with ids: ${caseIds}`
      );
    }
  }
  async getCaseFilesCsvGenerationStatus(
    organizationId: string,
    caseId: string
  ): Promise<ExportCaseFilesToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/case/${caseId}/files/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch csv generation from case files status for organization with id: ${organizationId}`
      );
    }
  }
  async startCsvGenerationFromCaseFiles(
    organizationId: string,
    caseId: string,
    options: GetCaseFilesForCaseQueryOptions = {}
  ): Promise<void> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
      nestedFolder1 = null,
      nestedFolder2 = null,
      filePath = null,
      minFileSize = null,
      maxFileSize = null,
      type = null,
    } = options;
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/case/${caseId}/files/csv`,
        null,
        {
          params: {
            Status: status,
            Extension: extension,
            NestedFolder1: nestedFolder1,
            NestedFolder2: nestedFolder2,
            FilePath: filePath,
            MinFileSize: minFileSize,
            MaxFileSize: maxFileSize,
            PageNumber: pageNumber,
            PageSize: pageSize,
            OrderBy: orderBy,
            Descending: descending,
            Type: type,
          },
          paramsSerializer: {
            indexes: true,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start CSV generation from case files caseId: ${caseId}`
      );
    }
  }
  async getIntakesDeletionStatus(
    organizationId: string
  ): Promise<DeleteIntakeRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/intakes/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to fetch intakes deletion status for organization with id: ${organizationId}`
      );
    }
  }

  // Intake methods
  async startIntakesDeletion(
    organizationId: string,
    intakeIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/intakes/delete`,
        {
          intakeIds: intakeIds,
        }
      );
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to start intakes deletion with ids: ${intakeIds}`
      );
    }
  }

  async getIntakeCsvGenerationStatus(
    organizationId: string
  ): Promise<ExportIntakesToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/intakes/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to fetch csv generation from intake status for organization with id: ${organizationId}`
      );
    }
  }

  async startCsvGenerationFromIntakes(
    organizationId: string,
    intakes: { id: string; intakeNumber: string }[],
    exportFilesData: boolean
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/intakes/csv`,
        {
          intakes: intakes,
          exportFilesData: exportFilesData,
        }
      );
      return response.data;
    } catch (error) {
      const intakeIds = intakes.map((i) => i.id).join(", ");
      throw new Error(
        `Failed to start csv generation from intake with ids: ${intakeIds}`
      );
    }
  }

  async startIntakeFilesDeletion(
    organizationId: string,
    intakeId: string,
    fileIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/intake/${intakeId}/files/delete`,
        {
          fileIds: fileIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start intake files deletion with ids: ${fileIds}`
      );
    }
  }

  async getIntakeFilesDeletionStatus(
    organizationId: string,
    intakeId: string
  ): Promise<DeleteIntakeFilesRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/intake/${intakeId}/files/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch intake files deletion status for organization with id: ${organizationId}`
      );
    }
  }

  // FirmDocumentsFolder methods
  async getFirmDocumentsFoldersDeletionStatus(
    organizationId: string
  ): Promise<DeleteFirmDocumentsFolderRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/firmDocumentsFolders/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch firm documents folders deletion status for organization with id: ${organizationId}`
      );
    }
  }

  async startFirmDocumentsFoldersDeletion(
    organizationId: string,
    firmDocumentsFolderIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/firmDocumentsFolders/delete`,
        {
          firmDocumentsFolderIds: firmDocumentsFolderIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start firm documents folders deletion with ids: ${firmDocumentsFolderIds}`
      );
    }
  }

  async getFirmDocumentsFolderCsvGenerationStatus(
    organizationId: string
  ): Promise<ExportFirmDocumentsFoldersToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/firmDocumentsFolders/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch csv generation from firm documents folder status for organization with id: ${organizationId}`
      );
    }
  }

  async startCsvGenerationFromFirmDocumentsFolders(
    organizationId: string,
    firmDocumentsFolders: { id: string; folderPath: string }[],
    exportFilesData: boolean
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/firmDocumentsFolders/csv`,
        {
          firmDocumentsFolders: firmDocumentsFolders,
          exportFilesData: exportFilesData,
        }
      );
      return response.data;
    } catch (error) {
      const firmDocumentsFolderIds = firmDocumentsFolders
        .map((i) => i.id)
        .join(", ");
      throw new Error(
        `Failed to start csv generation from firm documents folder with ids: ${firmDocumentsFolderIds}`
      );
    }
  }

  async startFirmDocumentsDeletion(
    organizationId: string,
    firmDocumentsFolderId: string,
    fileIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/firmDocumentsFolder/${firmDocumentsFolderId}/files/delete`,
        {
          fileIds: fileIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start firm documents folder files deletion with ids: ${fileIds}`
      );
    }
  }

  async getFirmDocumentsDeletionStatus(
    organizationId: string,
    firmDocumentsFolderId: string
  ): Promise<DeleteFirmDocumentsRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/firmDocumentsFolder/${firmDocumentsFolderId}/files/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch firm documents folder files deletion status for organization with id: ${organizationId}`
      );
    }
  }

  async getFirmDocumentsCsvGenerationStatus(
    organizationId: string,
    firmDocumentsFolderId: string
  ): Promise<ExportFirmDocumentsToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/firmDocumentsFolder/${firmDocumentsFolderId}/files/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch csv generation from firm documents folder files status for organization with id: ${organizationId}`
      );
    }
  }

  async startCsvGenerationFromFirmDocuments(
    organizationId: string,
    firmDocumentsFolderId: string,
    options: GetFirmDocumentsForFirmDocumentsFolderQueryOptions = {}
  ): Promise<void> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
      filePath = null,
      minFileSize = null,
      maxFileSize = null,
      type = null,
    } = options;
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/firmDocumentsFolder/${firmDocumentsFolderId}/files/csv`,
        null,
        {
          params: {
            Status: status,
            Extension: extension,
            FilePath: filePath,
            MinFileSize: minFileSize,
            MaxFileSize: maxFileSize,
            PageNumber: pageNumber,
            PageSize: pageSize,
            OrderBy: orderBy,
            Descending: descending,
            Type: type,
          },
          paramsSerializer: {
            indexes: true,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start CSV generation from firm documents folder files firmDocumentsFolderId: ${firmDocumentsFolderId}`
      );
    }
  }

  //Providers methods
  async getProvidersDeletionStatus(
    organizationId: string
  ): Promise<DeleteProviderRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/providers/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch providers deletion status for organization with id: ${organizationId}`
      );
    }
  }
  async startProvidersDeletion(
    organizationId: string,
    providerIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/providers/delete`,
        {
          providerIds: providerIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start providers deletion with ids: ${providerIds}`
      );
    }
  }
  async getProviderFilesDeletionStatus(
    organizationId: string,
    providerId: string
  ): Promise<DeleteProviderFilesRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/provider/${providerId}/files/delete/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch provider files deletion status for organization with id: ${organizationId}`
      );
    }
  }
  async startProviderFilesDeletion(
    organizationId: string,
    providerId: string,
    fileIds: string[]
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/provider/${providerId}/files/delete`,
        {
          fileIds: fileIds,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start provider files deletion with ids: ${fileIds}`
      );
    }
  }
  async getProvidersCsvGenerationStatus(
    organizationId: string
  ): Promise<ExportProvidersToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/providers/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch csv generation from providers status for organization with id: ${organizationId}`
      );
    }
  }
  async startCsvGenerationFromProviders(
    organizationId: string,
    providers: { id: string; providerNameId: string }[],
    exportFilesData: boolean
  ): Promise<void> {
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/providers/csv`,
        {
          providers: providers,
          exportFilesData: exportFilesData,
        }
      );
      return response.data;
    } catch (error) {
      const providerIds = providers.map((p) => p.id).join(", ");
      throw new Error(
        `Failed to start CSV generation from providers with ids: ${providerIds}`
      );
    }
  }
  async getProviderFilesCsvGenerationStatus(
    organizationId: string,
    providerId: string
  ): Promise<ExportProviderFilesToCsvRequest[]> {
    try {
      const response = await this.api.get(
        `/api/jobs/${organizationId}/provider/${providerId}/files/csv/status`
      );
      return response.data.map((job: any) => transformJobRequest(job));
    } catch (error) {
      throw new Error(
        `Failed to fetch csv generation from provider files status for organization with id: ${organizationId}`
      );
    }
  }
  async startCsvGenerationFromProviderFiles(
    organizationId: string,
    providerId: string,
    options: GetProviderFilesForProviderQueryOptions = {}
  ): Promise<void> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
      filePath = null,
      minFileSize = null,
      maxFileSize = null,
      type = null,
    } = options;
    try {
      const response = await this.api.post(
        `/api/jobs/${organizationId}/provider/${providerId}/files/csv`,
        null,
        {
          params: {
            Status: status,
            Extension: extension,
            FilePath: filePath,
            MinFileSize: minFileSize,
            MaxFileSize: maxFileSize,
            PageNumber: pageNumber,
            PageSize: pageSize,
            OrderBy: orderBy,
            Descending: descending,
            Type: type,
          },
          paramsSerializer: {
            indexes: true,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        `Failed to start CSV generation from provider files providerId: ${providerId}`
      );
    }
  }
}

export default JobsRepository;
