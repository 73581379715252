import { AxiosResponse } from "axios";
import { Provider, ProviderWithStatistics } from "../models/Provider";
import { Paging } from "../models/Paging";
import useApi from "../services/ApiService";
import { saveAs } from "file-saver";
import { ProviderFile } from "../models/ProviderFile";

export interface GetProvidersQueryOptions {
  organizationId?: string;
  status?: string[] | null;
  isLocked?: boolean | null;
  createdBy?: string[];
  providerNameId?: string[] | null;
  ids?: string[];
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
}
export class GetProviderFilesForProviderQueryOptions {
  status?: string[] | null;
  extension?: string[] | null;
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  descending?: boolean;
  filePath?: string | null;
  minFileSize?: number | null;
  maxFileSize?: number | null;
  type?: number | null;
}

export class ProvidersRepository {
  private api = useApi();

  async getProviders(
    options: GetProvidersQueryOptions = {}
  ): Promise<{ data: Provider[]; paging: Paging | null }> {
    const {
      status = null,
      isLocked = null,
      createdBy = null,
      providerNameId = null,
      ids = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
    } = options;

    try {
      const response = await this.api.get("/api/Providers", {
        params: {
          Status: status,
          IsLocked: isLocked,
          CreatedBy: createdBy,
          ProviderNameId: providerNameId,
          Id: ids,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }

      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error("Failed to fetch providers");
    }
  }

  async getProvider(id: string): Promise<ProviderWithStatistics> {
    try {
      const response = await this.api.get(`/api/Providers/${id}`);
      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch provider with id: ${id}`);
    }
  }

  async getProviderFilesForProvider(
    id: string,
    options: GetProviderFilesForProviderQueryOptions = {}
  ): Promise<{ data: ProviderFile[]; paging: Paging | null }> {
    const {
      status = null,
      extension = null,
      pageNumber = null,
      pageSize = null,
      orderBy = null,
      descending = true,
      filePath = null,
      minFileSize = null,
      maxFileSize = null,
    } = options;
    try {
      const response = await this.api.get(`/api/Providers/${id}/files`, {
        params: {
          Status: status,
          Extension: extension,
          FilePath: filePath,
          MinFileSize: minFileSize,
          MaxFileSize: maxFileSize,
          PageNumber: pageNumber,
          PageSize: pageSize,
          OrderBy: orderBy,
          Descending: descending,
        },
        paramsSerializer: {
          indexes: true,
        },
        timeout: 100 * 1000,
      });

      const { data, headers } = response;
      let paging: Paging | null = null;
      const paginationHeader = headers["x-pagination"];
      if (paginationHeader) {
        paging = JSON.parse(paginationHeader || "");
      }
      return { data, paging };
    } catch (error) {
      // Handle error
      throw new Error(`Failed to fetch provider with id: ${id}`);
    }
  }
  async updateProviderBlock(providerId: string, block: boolean): Promise<void> {
    try {
      const data: Record<string, any> = {
        entityId: providerId,
        block: block,
      };

      const response: AxiosResponse<void> = await this.api.patch(
        `/api/Providers/updateBlock`,
        data
      );

      return response.data;
    } catch (error) {
      // Handle error
      throw new Error(
        `Failed to update block info of provider with id: ${providerId}`
      );
    }
  }

  async downloadSharepointFile(
    providerId: string,
    providerFileId: string
  ): Promise<void> {
    try {
      const data: Record<string, any> = {
        entityId: providerId,
        fileId: providerFileId,
      };

      const response: AxiosResponse<any> = await this.api.post(
        `/api/providers/sharepoint/download`,
        data
      );
      saveAs(new Blob([response.data.file]), response.data.name);
    } catch (error) {
      throw new Error(
        `Failed to download file for providerId: ${providerId} and providerFileId: ${providerFileId}`
      );
    }
  }
}

export default ProvidersRepository;
