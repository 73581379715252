import React, { useState } from "react";
import { useQueryClient } from "react-query";
import {OrganizationConfiguration} from "../../models/OrganizationConfiguration/OrganizationConfiguration";
import ConfirmSaveChangesModal from "../common/ConfirmSaveChangesModal";
import OrganizationsRepository from "../../repositories/OrganizationsRepository";

import {
  Box,
  Button,
  IconButton,
  Sheet,
  Stack,
  Table,
  Typography
} from "@mui/joy";

import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";


interface DropboxApiAuthConfigurationSectionProps {
  organizationId: string,
  value: OrganizationConfiguration;
  onChange: (updatedConfig: OrganizationConfiguration) => void;
}

const DropboxApiAuthConfigurationSection: React.FC<DropboxApiAuthConfigurationSectionProps> = ({
  organizationId,
  value, 
  onChange 
}) => {
  const organizationsRepository = new OrganizationsRepository();
  const [isRefreshTokenVisible, setIsRefreshTokenVisible] = useState(false);
  const [deleteRefreshTokenId, setDeleteRefreshTokenId] = useState<string | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const toggleRefreshTokenVisibility = () => {
    setIsRefreshTokenVisible((prevState) => !prevState);
  };
  
  const handleDeleteDropboxRefreshToken = (deleteRefreshTokenId: string) => {
    setDeleteRefreshTokenId(deleteRefreshTokenId);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (deleteRefreshTokenId) {
      try {
        await organizationsRepository.deleteDropboxRefreshToken(deleteRefreshTokenId)
        // await organizationsRepository.deleteDropboxRefreshToken(deleteRefreshTokenId).then(() => {
        //   onChange({
        //     ...value,
        //     dropboxRefreshTokens: value.dropboxRefreshTokens?.filter(
        //       (dropboxRefreshToken) => dropboxRefreshToken.id !== deleteRefreshTokenId
        //     ),
        //   });
        // });
      } catch (error) {
        console.error("Failed to delete client:", error);
      }
    }

    handleCloseDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setDeleteRefreshTokenId(null);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <Sheet
        className="DropboxTableContainer"
        variant="outlined"
        sx={{
          display: "initial",
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          sx={{
            "--TableCell-headBackground": "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground": "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
          <tr>
            <th style={{ width: "2%", padding: "12px 6px" }}></th>
            <th style={{ width: "30%", padding: "12px 6px" }}>Name</th>
            <th style={{ width: "60%", padding: "12px 6px" }}>Refresh Token</th>
            <th style={{ width: "10%", padding: "12px 6px", textAlign: "center" }}></th>
          </tr>
          </thead>
          <tbody>
          {value.dropboxRefreshTokens != null && value.dropboxRefreshTokens.map((dropboxRefreshToken) => (
            <tr key={dropboxRefreshToken.name}>
              <td/>
              <td>
                <Typography level="body-md">{dropboxRefreshToken.name}</Typography>
              </td>
              <td>
                <Box sx={{ minHeight: 40}}>
                  {isRefreshTokenVisible ? (
                    <Stack direction="row" padding={0} alignItems="center" spacing={1}>
                      <IconButton
                        onClick={toggleRefreshTokenVisibility}
                        variant="plain"
                        color="neutral"
                      >
                        <VisibilityOffIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                      <Typography
                        sx={{ py: 1, px: 0, wordBreak: "break-word", color: "white" }}
                        level="body-sm"
                      >
                        {dropboxRefreshToken.refreshToken}
                      </Typography>
                    </Stack>
                  ) : (
                    <Button
                      onClick={toggleRefreshTokenVisibility}
                      variant="plain"
                      color="neutral"
                      startDecorator={<VisibilityIcon sx={{ padding: "0px" }} />}
                    >
                      Show Refresh Token
                    </Button>
                  )}
                </Box>
              </td>
              <td style={{ textAlign: "center" }}>
                <IconButton
                  aria-label="Delete"
                  size="sm"
                  color="danger"
                  onClick={() => handleDeleteDropboxRefreshToken(dropboxRefreshToken.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Sheet>
  
      <ConfirmSaveChangesModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onSaveChanges={handleConfirmDelete}
        message={() => "Are you sure you want to delete this refresh token?"}
      />
    </>
  );
};

export default DropboxApiAuthConfigurationSection;
