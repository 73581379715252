/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { FirmDocument } from "../../models/FirmDocumentFolder/FirmDocument";
import JobsRepository from "../../repositories/JobsRepository";
import ConfirmFirmDocumentsDeletionModal from "./ConfirmFirmDocumentsDeletionModal";

import {
  Button,
  IconButton,
  Sheet,
  Stack,
  Table,
  Tooltip,
  Typography,
  useTheme
} from "@mui/joy";

import { 
  Delete as DeleteIcon
} from "@mui/icons-material";


interface CreateDeleteFirmDocumentsRequestTableProps {
  organizationId: string;
  firmDocumentsFolderId: string;
  folderPath: string;
  newFilesForDeletion: FirmDocument[];
  setNewFirmDocumentsForDeletion: React.Dispatch<React.SetStateAction<FirmDocument[]>>;
}

const CreateDeleteFirmDocumentsRequestTable: React.FC<
  CreateDeleteFirmDocumentsRequestTableProps
> = ({
  organizationId,
  firmDocumentsFolderId,
  folderPath,
  newFilesForDeletion,
  setNewFirmDocumentsForDeletion,
}) => {
  const theme = useTheme();
  const jobsRepository = new JobsRepository();
  const queryClient = useQueryClient();
  const allRows = [...newFilesForDeletion];
  const newIds = new Set(newFilesForDeletion.map((c) => c.id));
  const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] = useState<boolean>(false);
  
  const getFileName = (filePath: string) => {
    return filePath.split("\\").pop();
  };
  
  return (
    <Stack gap={2} maxHeight={"40%"} minHeight={"40%"}>
      <Button
        disabled={!(newFilesForDeletion.length > 0)}
        onClick={() => setConfirmDeletionModalOpen(true)}
      >
        Delete files
      </Button>
      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "5%",
                  minWidth: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              ></th>

              <th
                style={{
                  width: "40%",
                  padding: "12px 6px",
                }}
              >
                File Name
              </th>

              <th></th>
            </tr>
          </thead>

          <tbody>
            {allRows.map((firmDocumentForDeletion) => {
              return (
                <tr
                  key={firmDocumentForDeletion.id}
                  style={{
                    backgroundColor: newIds.has(firmDocumentForDeletion.id)
                      ? theme.palette.primary.plainHoverBg
                      : "inherit",
                  }}
                >
                  <td style={{ textAlign: "center", width: 120 }}></td>
                  <td>
                    <Tooltip title={firmDocumentForDeletion.filePath}>
                      <Typography level="body-sm">
                        {getFileName(firmDocumentForDeletion.filePath)}
                      </Typography>
                    </Tooltip>
                  </td>
                  <td
                    style={{
                      padding: "12px 6px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="Cancel"
                      size="sm"
                      color="danger"
                      onClick={() => {
                        setNewFirmDocumentsForDeletion((prevRows) =>
                          prevRows.filter(
                            (entity) => entity.id !== firmDocumentForDeletion.id
                          )
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      
      {confirmDeletionModalOpen ? (
        <ConfirmFirmDocumentsDeletionModal
          open={confirmDeletionModalOpen}
          folderPath={folderPath}
          filePaths={newFilesForDeletion.map((f) => f.filePath)}
          onClose={() => {
            setConfirmDeletionModalOpen(false);
          }}
          onConfirm={async () => {
            await jobsRepository.startFirmDocumentsDeletion(
              organizationId,
              firmDocumentsFolderId,
              newFilesForDeletion.map((c) => c.id)
            );
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries([
              "deleteFirmDocuments",
              organizationId,
              firmDocumentsFolderId,
            ]);
            queryClient.invalidateQueries(["firmDocuments", firmDocumentsFolderId]);
            setNewFirmDocumentsForDeletion([]);
            setConfirmDeletionModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default CreateDeleteFirmDocumentsRequestTable;
