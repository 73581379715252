/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
import JobsRepository from "../../repositories/JobsRepository";
import {FirmDocumentsFolder} from "../../models/FirmDocumentFolder/FirmDocumentsFolder";

import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  Table,
  Sheet,
  Typography,
  useTheme,
} from "@mui/joy";

import { Delete } from "@mui/icons-material";
import ConfirmNewExportFirmDocumentsFoldersToCsvModal from "./ConfirmNewExportFirmDocumentsFoldersToCsvModal";
import ShortenedPath from "../common/ShortenedPath";

interface CreateExportFirmDocumentsFoldersToCsvTableProps {
  organizationId: string;
  newFirmDocumentsFoldersForExport: FirmDocumentsFolder[];
  setNewFirmDocumentsFoldersForExport: React.Dispatch<React.SetStateAction<FirmDocumentsFolder[]>>;
}

const CreateExportFirmDocumentsFoldersToCsvTable: React.FC<
  CreateExportFirmDocumentsFoldersToCsvTableProps
> = ({ organizationId, newFirmDocumentsFoldersForExport, setNewFirmDocumentsFoldersForExport }) => {
  const theme = useTheme();
  const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] = useState<boolean>(false);
  const [exportFilesData, setExportFilesData] = useState<boolean>(false);
  const allRows = [...newFirmDocumentsFoldersForExport];
  const newIds = new Set(newFirmDocumentsFoldersForExport.map((c) => c.id));
  const jobsRepository = new JobsRepository();
  const queryClient = useQueryClient();
  
  return (
    <Stack 
      gap={2} 
      maxHeight={"40%"}
      minHeight={"40%"}>
      <Stack gap={1}>
        <Button
          disabled={!(newFirmDocumentsFoldersForExport.length > 0)}
          onClick={() => setConfirmDeletionModalOpen(true)}
        >
          Export
        </Button>
        <FormControl
          orientation="horizontal"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormLabel>Export files data</FormLabel>
          <Checkbox
            sx={{ float: "right" }}
            checked={exportFilesData}
            onChange={(e) => setExportFilesData(e.target.checked)}
          />
        </FormControl>
      </Stack>
      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: "5%",
                  minWidth: 48,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              ></th>

              <th
                style={{
                  width: "75%",
                  padding: "12px 6px",
                }}
              >
                Folder Path
              </th>

              <th></th>
            </tr>
          </thead>

          <tbody>
            {allRows.map((deleteFirmDocumentsFolder) => {
              return (
                <tr
                  key={deleteFirmDocumentsFolder.id}
                  style={{
                    backgroundColor: newIds.has(deleteFirmDocumentsFolder.id)
                      ? theme.palette.primary.plainHoverBg
                      : "inherit",
                  }}
                >
                  <td style={{ textAlign: "center", width: 120 }}></td>
                  <td>
                    <ShortenedPath
                      folderPath={deleteFirmDocumentsFolder.folderPath}
                      maxLength={50}
                    />
                  </td>
                  <td
                    style={{
                      padding: "12px 6px",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="Cancel"
                      size="sm"
                      color="danger"
                      onClick={() => {
                        setNewFirmDocumentsFoldersForExport((prevRows) =>
                          prevRows.filter(
                            (entity) => entity.id !== deleteFirmDocumentsFolder.id
                          )
                        );
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      {confirmDeletionModalOpen ? (
        <ConfirmNewExportFirmDocumentsFoldersToCsvModal
          open={confirmDeletionModalOpen}
          folderPaths={newFirmDocumentsFoldersForExport.map((f) => f.folderPath)}
          exportFilesData={exportFilesData}
          onClose={() => {
            setConfirmDeletionModalOpen(false);
          }}
          onConfirm={async () => {
            await jobsRepository.startCsvGenerationFromFirmDocumentsFolders(
              organizationId,
              newFirmDocumentsFoldersForExport.map((f) => ({ id: f.id, folderPath: f.folderPath })),
              exportFilesData
            );
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries(["exportFirmDocumentsFoldersToCsv", organizationId]);
            setNewFirmDocumentsFoldersForExport([]);
            setConfirmDeletionModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default CreateExportFirmDocumentsFoldersToCsvTable;
