import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Chip, Divider, Modal, ModalDialog, Stack } from "@mui/joy";
import Button from "@mui/joy/Button";
import DialogActions from "@mui/joy/DialogActions";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import React from "react";
import {
  JobEntityType,
  JobEntityTypeColors,
} from "../../models/Jobs/JobEntityType";

interface ConfirmProvidersDeletionModalProps {
  open: boolean;
  providerNameIds: string[];
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmProvidersDeletionModal: React.FC<
  ConfirmProvidersDeletionModalProps
> = ({ open, providerNameIds, onClose, onConfirm }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" role="alertdialog" maxWidth={"35%"}>
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmation required
        </DialogTitle>
        <Divider />
        <DialogContent>
          Are you sure you want to delete providers?
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            sx={{ flexWrap: "wrap", mt: 2 }}
          >
            {providerNameIds.map((providerNameId) => (
              <Chip
                key={providerNameId}
                variant="soft"
                size={"sm"}
                color={JobEntityTypeColors[JobEntityType.Provider]}
              >
                {providerNameId}
              </Chip>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="solid" onClick={() => onConfirm()} color="primary">
            Confirm
          </Button>
          <Button variant="plain" color="neutral" onClick={() => onClose()}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmProvidersDeletionModal;
