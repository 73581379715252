/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import {useEffect, useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import Fuse from "fuse.js";
import {ClearableSelect} from "../common/ClearableSelect";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import JobRequestStatusChip from "../Jobs/JobRequestStatusChip";
import AbortJobModal from "../Jobs/AbortJobModal";
import ConfirmFirmDocumentsFoldersDeletionModal from "./ConfirmFirmDocumentsFoldersDeletionModal";
import {compareBy, Order, stableSort} from "../../helpers/TableHelpers";
import JobsRepository from "../../repositories/JobsRepository";
import {JobRequestStatus, JobRequestStatusColors, JobRequestStatusLabels,} from "../../models/Jobs/JobRequestStatus";
import {DeleteFirmDocumentsFolderRequest, JobRequest} from "../../models/Jobs/JobRequest";
import ShortenedPath from "../common/ShortenedPath";

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Option,
  Sheet,
  Table,
  Typography,
  Button,
  IconButton,
  Skeleton,
  useTheme
} from "@mui/joy";

import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

interface DeleteFirmDocumentsFoldersTableProps {
  organizationId: string;
  newFirmDocumentsFoldersForDeletion: DeleteFirmDocumentsFolderRequest[];
  setNewFirmDocumentsFoldersForDeletion: React.Dispatch<React.SetStateAction<DeleteFirmDocumentsFolderRequest[]>>;
}

const DeleteFirmDocumentsFoldersTable: React.FC<DeleteFirmDocumentsFoldersTableProps> = ({
  organizationId,
  newFirmDocumentsFoldersForDeletion,
  setNewFirmDocumentsFoldersForDeletion,
}) => {
  
  const theme = useTheme();
  const queryClient = useQueryClient();
  const jobsRepository = new JobsRepository();
  
  const [order, setOrder] = React.useState<Order>("asc");
  const [sort, setSort] = React.useState<keyof JobRequest>("status");
  const [statusFilter, setStatusFilter] = React.useState<string | null>(null);
  const [searchForFolderPath, setSearchForFolderPath] = useState<string | null>("");
  const [abortJob, setAbortJob] = useState<JobRequest | null>(null);
  const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] = useState<boolean>(false);
  const [firmDocumentsFoldersDeletionStatus, setFirmDocumentsFoldersDeletionStatus] = useState<DeleteFirmDocumentsFolderRequest[] | null>(null);
  
  const firmDocumentsFoldersQuery = useQuery(
    ["firmDocumentsFoldersDeletionStatus", organizationId],
    async () => {
      return await jobsRepository.getFirmDocumentsFoldersDeletionStatus(organizationId);
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    }
  );
  
  useEffect(() => {
    if (firmDocumentsFoldersQuery.data) {
      setFirmDocumentsFoldersDeletionStatus(firmDocumentsFoldersQuery.data);
    }
  }, [firmDocumentsFoldersQuery]);
  
  const handleSearch = (newFolderPath: string) => {
    setSearchForFolderPath(newFolderPath ?? null);
  };

  const changeSort = (newSort: keyof JobRequest) => {
    setSort(newSort);
  };
  
  const changeOrder = (newOrder: Order) => {
    setOrder(newOrder);
  };

  const { isError, isFetching } = firmDocumentsFoldersQuery;
  
  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <ClearableSelect
          value={statusFilter}
          color={JobRequestStatusColors[statusFilter as JobRequestStatus]}
          onChange={(newValue) => {
            setStatusFilter(newValue);
          }}
          size="sm"
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
        >
          {Object.values(JobRequestStatus).map((status, idx) => {
            const deleteRequestStatus = status as JobRequestStatus;
            return (
              <Option
                key={idx}
                value={deleteRequestStatus}
                color={JobRequestStatusColors[deleteRequestStatus]}
              >
                {JobRequestStatusLabels[deleteRequestStatus]}
              </Option>
            );
          })}
        </ClearableSelect>
      </FormControl>
    </React.Fragment>
  );
  
  const filteredJobs = (firmDocumentsFoldersDeletionStatus || []).filter((job) => {
    return statusFilter == null || job.status === statusFilter;
  });
  
  const searchOptions = {
    keys: ["folderPath"],
    includeScore: true,
    threshold: 0.1,
    caseSensitive: false,
  };
  
  const fuse = new Fuse(filteredJobs, searchOptions);
  const searchResults = searchForFolderPath
    ? fuse.search(searchForFolderPath).map((result) => result.item)
    : filteredJobs;
  
  const sortedJobs = sort
    ? stableSort<DeleteFirmDocumentsFolderRequest>(searchResults, compareBy(order, sort))
    : filteredJobs;
  
  const allRows = [...newFirmDocumentsFoldersForDeletion, ...sortedJobs];
  const newIds = new Set(newFirmDocumentsFoldersForDeletion.map((f) => f.migrationEntityId));

  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          pt: 0.5,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for folder</FormLabel>
          <Input
            size="sm"
            placeholder="Folder path"
            startDecorator={<SearchIcon />}
            onChange={(event) => handleSearch(event.target.value)}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Button
        disabled={!(newFirmDocumentsFoldersForDeletion.length > 0)}
        onClick={() => setConfirmDeletionModalOpen(true)}
        sx={{ mb: 0.5 }}
      >
        Delete Firm Documents Folders
      </Button>
      <Sheet
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            stickyFooter
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "5%",
                    minWidth: 48,
                    textAlign: "center",
                    padding: "12px 6px",
                  }}
                ></th>

                <th
                  style={{
                    width: "50%",
                    padding: "12px 6px",
                  }}
                >
                  <SortableTableColumnHeader
                    label={"Folder Path"}
                    sortProperty={"folderPath"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>

                <th style={{ width: "25%", padding: "12px 6px" }}>
                  <SortableTableColumnHeader
                    label={"Status"}
                    sortProperty={"status"}
                    getSort={() => sort}
                    setSort={(newSort) =>
                      changeSort(newSort as keyof JobRequest)
                    }
                    getOrder={() => order}
                    setOrder={(newOrder) => changeOrder(newOrder)}
                  />
                </th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {allRows.map((deleteFirmDocumentsFolderJob : DeleteFirmDocumentsFolderRequest) => {
                const canEdit =
                  deleteFirmDocumentsFolderJob.status === JobRequestStatus.Pending ||
                  deleteFirmDocumentsFolderJob.status === JobRequestStatus.New ||
                  deleteFirmDocumentsFolderJob.status === JobRequestStatus.Completed;
                
                return (
                  <tr
                    key={deleteFirmDocumentsFolderJob.migrationEntityId}
                    style={{
                      backgroundColor: newIds.has(deleteFirmDocumentsFolderJob.migrationEntityId)
                        ? theme.palette.primary.plainHoverBg
                        : "inherit",
                    }}
                  >
                    <td style={{ textAlign: "center", width: 120 }}></td>
                    <td>
                      <Typography level="body-sm">
                        <ShortenedPath
                          folderPath={deleteFirmDocumentsFolderJob.folderPath}
                          maxLength={20}
                        />
                      </Typography>
                    </td>
                    <td>
                      <JobRequestStatusChip status={deleteFirmDocumentsFolderJob.status} />
                    </td>
                    {canEdit ? (
                      <td>
                        <IconButton
                          aria-label="Cancel"
                          size="sm"
                          color="danger"
                          onClick={() => {
                            deleteFirmDocumentsFolderJob.id
                              ? setAbortJob(deleteFirmDocumentsFolderJob)
                              : setNewFirmDocumentsFoldersForDeletion((prevRows) =>
                                  prevRows.filter((entity) =>
                                      entity.migrationEntityId !== deleteFirmDocumentsFolderJob.migrationEntityId
                                ));
                          }}
                        >
                          {
                            deleteFirmDocumentsFolderJob.status === JobRequestStatus.Completed 
                              ? (<DeleteIcon />)
                              : (<CancelIcon />)
                          }
                          {" "}
                        </IconButton>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Skeleton>
      </Sheet>
      {abortJob ? (
        <AbortJobModal
          open={abortJob !== null}
          jobRequest={abortJob}
          onClose={() => {
            setAbortJob(null);
          }}
          onConfirm={async () => {
            await jobsRepository.deleteJob(abortJob.id);
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries([
              "firmDocumentsFoldersDeletionStatus",
              organizationId,
            ]);
            if (abortJob.status !== JobRequestStatus.Completed) {
              queryClient.invalidateQueries(["firmDocumentsFolders", organizationId]);
            }
            setAbortJob(null);
          }}
        />
      ) : (
        <></>
      )}
      {confirmDeletionModalOpen ? (
        <ConfirmFirmDocumentsFoldersDeletionModal
          open={confirmDeletionModalOpen}
          folderPaths={newFirmDocumentsFoldersForDeletion.map((f) => f.folderPath)}
          onClose={() => {
            setConfirmDeletionModalOpen(false);
          }}
          onConfirm={async () => {
            await jobsRepository.startFirmDocumentsFoldersDeletion(
              organizationId,
              newFirmDocumentsFoldersForDeletion.map((f) => f.migrationEntityId)
            );
            queryClient.invalidateQueries("jobs");
            queryClient.invalidateQueries([
              "firmDocumentsFoldersDeletionStatus",
              organizationId,
            ]);
            queryClient.invalidateQueries(["firmDocumentsFolders", organizationId]);
            setNewFirmDocumentsFoldersForDeletion([]);
            setConfirmDeletionModalOpen(false);
          }}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default DeleteFirmDocumentsFoldersTable;
