const { DateTime } = require("luxon");

export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
export const convertUtcToLocal = (utcTime: string): string => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTime = DateTime.fromISO(utcTime, { zone: "utc" })
    .setZone(currentTimeZone)
    .toLocal();
  return localTime.toJSDate().toLocaleString();
};
export const roundToTwoDecimalPoints = (value: number): string => {
  return value.toFixed(2);
};
export const getFormattedDate = (date: string): string => {
  const luxonDate = DateTime.fromISO(date);
  return luxonDate.toFormat("dd/MM/yyyy");
};
export function filterValidProperties<T extends object>(
  obj: any,
  emptyInstance: T,
  excludeProperties: (keyof T)[] = []
): Partial<T> {
  const validKeys = Object.keys(emptyInstance) as (keyof T)[];
  const filteredProperties: Partial<T> = {};
  validKeys.forEach((key) => {
    if (!excludeProperties.includes(key) && key in obj) {
      filteredProperties[key] = obj[key];
    }
  });
  return filteredProperties;
}
export function getNonNullProperties<T extends object>(obj: T): Partial<T> {
  const nonNullProperties: Partial<T> = {};
  (Object.keys(obj) as (keyof T)[]).forEach((key) => {
    const value = obj[key as keyof T];
    if (value !== null && value !== undefined) {
      nonNullProperties[key] = value;
    }
  });
  return nonNullProperties;
}

export function getExtension(filePath: string): string {
  const lastDotIndex = filePath.lastIndexOf(".");
  if (lastDotIndex < 0 || lastDotIndex === filePath.length - 1) {
    return "";
  }
  return filePath.substring(lastDotIndex);
}
