import React from "react";
import ConfirmationModal from "../common/ConfirmationModal";

interface ConfirmProviderFlagUpdateModalProps {
  open: boolean;
  providerNameId: string;
  isBlocked: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmProviderBlockUpdateModal: React.FC<
  ConfirmProviderFlagUpdateModalProps
> = ({ open, providerNameId, isBlocked, onClose, onConfirm }) => {
  const getMessage = (): string => {
    if (isBlocked) {
      return `Are you sure you want to block provider ${providerNameId} migration?`;
    } else {
      return `Are you sure you want to unblock provider ${providerNameId} migration?`;
    }
  };

  const getStatusColor = (): "warning" | "lime" => {
    if (isBlocked) {
      return "warning";
    } else {
      return "lime";
    }
  };

  const getButtonText = (): string => {
    if (isBlocked) {
      return "Block";
    } else {
      return "Unblock";
    }
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      message={getMessage()}
      statusColor={getStatusColor()}
      buttonText={getButtonText()}
    />
  );
};

export default ConfirmProviderBlockUpdateModal;
